const products = [
  {
    id: 'prod_QjsQSSZbBcaLNS',
    metadata: {},
    active: false,
    name: 'Annulation Dépassement',
    type: 'service',
    prices: [
      {
        id: 'price_1PsOfpB0P8XBvhyKLNyGNoYK',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1724762341,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 3674,
            unit_amount_decimal: '3674'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_QjsQSSZbBcaLNS',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 3674,
        unit_amount_decimal: '3674'
      }
    ]
  },
  {
    id: 'prod_enterpriseMetered',
    metadata: {
      WARNING: 'DO NOT EDIT METADATA !!! 🙏',
      meteredCriteria: 'pageViews',
      services: 'tcf,analyticsConnectors',
      statsAllowedAggregateBy: 'day,week,month,year',
      statsAreFiltersAllowed: 'true',
      statsKpi: 'pageview,visitor,newVisitor,consentRate,interactionRate,optInRate,hits,quickBounceRate,consentRateMobile,consentRateDesktop,interactionRateMobile,interactionRateDesktop',
      statsPeriod: 'lastYear',
      statsWidgets: 'dailyOverview,dailyVisitsOverview,visitsByHosts,consentDistribution,optInRateByDevice,devicesDistribution,consentDistributionByDevice,optInRateByConfig,dailyRates,interactionRate,bounceRateByDuration',
      title_EN: 'Graduated Enterprise plan',
      title_FR: 'Offre Entreprise progressive',
      zd_priority: 'high'
    },
    active: true,
    name: 'Graduated Enterprise',
    type: 'service',
    prices: [
      {
        id: 'price_1Plp95B0P8XBvhyKHPs1wL7S',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1723195803,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customPrice: 'true',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1Plp8xB0P8XBvhyK8nohDFTh',
          graduatedFirstPaidTierFees: '{"eur":0.00032,"usd":0.00032,"cad":0.00032,"chf":0.00032,"gbp":0.00032}',
          multiCurrency: 'true',
          services: 'cookies,processings,dpo',
          yearlyFlatFees: '{"eur":5001,"usd":5001,"cad":5001,"chf":5001,"gbp":5001}',
          yearlyMinUsage: '12000000'
        },
        nickname: 'yearly 12M page views/year (custom 5001)',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 12000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.032',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1Plp8xB0P8XBvhyK8nohDFTh',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1723195795,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500100,
            unit_amount_decimal: '500100'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500100,
            unit_amount_decimal: '500100'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500100,
            unit_amount_decimal: '500100'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500100,
            unit_amount_decimal: '500100'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500100,
            unit_amount_decimal: '500100'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customPrice: 'true',
          multiCurrency: 'true',
          tieredPriceId: 'price_1Plp95B0P8XBvhyKHPs1wL7S',
          yearlyFlatFees: '{"eur":5001,"usd":5001,"cad":5001,"chf":5001,"gbp":5001}'
        },
        nickname: 'Flat fee for yearly 12M page views/year (custom 5001)',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 500100,
        unit_amount_decimal: '500100'
      },
      {
        id: 'price_1OgjNQB0P8XBvhyK3YaSMzDt',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707205652,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00035',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00035',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00035',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00035',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00035',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgjNPB0P8XBvhyKAyx3DqKT',
          graduatedFirstPaidTierFees: '{"eur":0.0000035,"usd":0.0000035,"cad":0.0000035,"chf":0.0000035,"gbp":0.0000035}',
          multiCurrency: 'true',
          services: 'cookies,processings,dpo',
          yearlyFlatFees: '{"eur":200000,"usd":200000,"cad":200000,"chf":200000,"gbp":200000}',
          yearlyMinUsage: '50000000000'
        },
        nickname: 'yearly 50B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 50000000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.00035',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgjNPB0P8XBvhyKAyx3DqKT',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707205651,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 20000000,
            unit_amount_decimal: '20000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 20000000,
            unit_amount_decimal: '20000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 20000000,
            unit_amount_decimal: '20000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 20000000,
            unit_amount_decimal: '20000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 20000000,
            unit_amount_decimal: '20000000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgjNQB0P8XBvhyK3YaSMzDt',
          yearlyFlatFees: '{"eur":200000,"usd":200000,"cad":200000,"chf":200000,"gbp":200000}'
        },
        nickname: 'Flat fee for yearly 50B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 20000000,
        unit_amount_decimal: '20000000'
      },
      {
        id: 'price_1OgjNMB0P8XBvhyKeDPgLihq',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707205648,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 35000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00043',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 35000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00043',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 35000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00043',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 35000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00043',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 35000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00043',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgjNLB0P8XBvhyKZ04Zop4K',
          graduatedFirstPaidTierFees: '{"eur":0.0000043,"usd":0.0000043,"cad":0.0000043,"chf":0.0000043,"gbp":0.0000043}',
          multiCurrency: 'true',
          services: 'cookies,processings,dpo',
          yearlyFlatFees: '{"eur":160000,"usd":160000,"cad":160000,"chf":160000,"gbp":160000}',
          yearlyMinUsage: '35000000000'
        },
        nickname: 'yearly 35B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 35000000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.00043',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgjNLB0P8XBvhyKZ04Zop4K',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707205647,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 16000000,
            unit_amount_decimal: '16000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 16000000,
            unit_amount_decimal: '16000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 16000000,
            unit_amount_decimal: '16000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 16000000,
            unit_amount_decimal: '16000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 16000000,
            unit_amount_decimal: '16000000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgjNMB0P8XBvhyKeDPgLihq',
          yearlyFlatFees: '{"eur":160000,"usd":160000,"cad":160000,"chf":160000,"gbp":160000}'
        },
        nickname: 'Flat fee for yearly 35B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 16000000,
        unit_amount_decimal: '16000000'
      },
      {
        id: 'price_1OgjNIB0P8XBvhyKy0002oXm',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707205644,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0005',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0005',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0005',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0005',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0005',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgjNIB0P8XBvhyKeVQaBftK',
          graduatedFirstPaidTierFees: '{"eur":0.000005,"usd":0.000005,"cad":0.000005,"chf":0.000005,"gbp":0.000005}',
          multiCurrency: 'true',
          services: 'cookies,processings,dpo',
          yearlyFlatFees: '{"eur":110000,"usd":110000,"cad":110000,"chf":110000,"gbp":110000}',
          yearlyMinUsage: '20000000000'
        },
        nickname: 'yearly 20B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 20000000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0005',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgjNIB0P8XBvhyKeVQaBftK',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707205644,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 11000000,
            unit_amount_decimal: '11000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 11000000,
            unit_amount_decimal: '11000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 11000000,
            unit_amount_decimal: '11000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 11000000,
            unit_amount_decimal: '11000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 11000000,
            unit_amount_decimal: '11000000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgjNIB0P8XBvhyKy0002oXm',
          yearlyFlatFees: '{"eur":110000,"usd":110000,"cad":110000,"chf":110000,"gbp":110000}'
        },
        nickname: 'Flat fee for yearly 20B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 11000000,
        unit_amount_decimal: '11000000'
      },
      {
        id: 'price_1OgjNFB0P8XBvhyKlYcRxvCv',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707205641,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00058',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00058',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00058',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00058',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00058',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgjNEB0P8XBvhyKSIF9rBJ8',
          graduatedFirstPaidTierFees: '{"eur":0.0000057999999999999995,"usd":0.0000057999999999999995,"cad":0.0000057999999999999995,"chf":0.0000057999999999999995,"gbp":0.0000057999999999999995}',
          multiCurrency: 'true',
          services: 'cookies,processings,dpo',
          yearlyFlatFees: '{"eur":90000,"usd":90000,"cad":90000,"chf":90000,"gbp":90000}',
          yearlyMinUsage: '15000000000'
        },
        nickname: 'yearly 15B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 15000000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.00058',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgjNEB0P8XBvhyKSIF9rBJ8',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707205640,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9000000,
            unit_amount_decimal: '9000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9000000,
            unit_amount_decimal: '9000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9000000,
            unit_amount_decimal: '9000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9000000,
            unit_amount_decimal: '9000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9000000,
            unit_amount_decimal: '9000000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgjNFB0P8XBvhyKlYcRxvCv',
          yearlyFlatFees: '{"eur":90000,"usd":90000,"cad":90000,"chf":90000,"gbp":90000}'
        },
        nickname: 'Flat fee for yearly 15B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9000000,
        unit_amount_decimal: '9000000'
      },
      {
        id: 'price_1OgjNBB0P8XBvhyKuqk55oYA',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707205637,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 10000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 10000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 10000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 10000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 10000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.00065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgjNAB0P8XBvhyKWyqNxBx7',
          graduatedFirstPaidTierFees: '{"eur":0.0000065,"usd":0.0000065,"cad":0.0000065,"chf":0.0000065,"gbp":0.0000065}',
          multiCurrency: 'true',
          services: 'cookies,processings,dpo',
          yearlyFlatFees: '{"eur":70000,"usd":70000,"cad":70000,"chf":70000,"gbp":70000}',
          yearlyMinUsage: '10000000000'
        },
        nickname: 'yearly 10B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 10000000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.00065',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgjNAB0P8XBvhyKWyqNxBx7',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707205636,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 7000000,
            unit_amount_decimal: '7000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 7000000,
            unit_amount_decimal: '7000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 7000000,
            unit_amount_decimal: '7000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 7000000,
            unit_amount_decimal: '7000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 7000000,
            unit_amount_decimal: '7000000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgjNBB0P8XBvhyKuqk55oYA',
          yearlyFlatFees: '{"eur":70000,"usd":70000,"cad":70000,"chf":70000,"gbp":70000}'
        },
        nickname: 'Flat fee for yearly 10B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 7000000,
        unit_amount_decimal: '7000000'
      },
      {
        id: 'price_1OgjN8B0P8XBvhyKqBcewPvx',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707205634,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0009',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0009',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0009',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0009',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0009',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgjN7B0P8XBvhyK9GMcwY23',
          graduatedFirstPaidTierFees: '{"eur":0.000008999999999999999,"usd":0.000008999999999999999,"cad":0.000008999999999999999,"chf":0.000008999999999999999,"gbp":0.000008999999999999999}',
          multiCurrency: 'true',
          services: 'cookies,processings,dpo',
          yearlyFlatFees: '{"eur":50000,"usd":50000,"cad":50000,"chf":50000,"gbp":50000}',
          yearlyMinUsage: '5000000000'
        },
        nickname: 'yearly 5B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 5000000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0009',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgjN7B0P8XBvhyK9GMcwY23',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707205633,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 5000000,
            unit_amount_decimal: '5000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 5000000,
            unit_amount_decimal: '5000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 5000000,
            unit_amount_decimal: '5000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 5000000,
            unit_amount_decimal: '5000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 5000000,
            unit_amount_decimal: '5000000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgjN8B0P8XBvhyKqBcewPvx',
          yearlyFlatFees: '{"eur":50000,"usd":50000,"cad":50000,"chf":50000,"gbp":50000}'
        },
        nickname: 'Flat fee for yearly 5B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 5000000,
        unit_amount_decimal: '5000000'
      },
      {
        id: 'price_1OgjN4B0P8XBvhyKATH2Jpet',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707205630,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 3000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 3000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 3000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 3000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 3000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgjN3B0P8XBvhyKZUZGM8ZP',
          graduatedFirstPaidTierFees: '{"eur":0.000011,"usd":0.000011,"cad":0.000011,"chf":0.000011,"gbp":0.000011}',
          multiCurrency: 'true',
          services: 'cookies,processings,dpo',
          yearlyFlatFees: '{"eur":37000,"usd":37000,"cad":37000,"chf":37000,"gbp":37000}',
          yearlyMinUsage: '3000000000'
        },
        nickname: 'yearly 3B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 3000000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0011',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgjN3B0P8XBvhyKZUZGM8ZP',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707205629,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 3700000,
            unit_amount_decimal: '3700000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 3700000,
            unit_amount_decimal: '3700000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 3700000,
            unit_amount_decimal: '3700000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 3700000,
            unit_amount_decimal: '3700000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 3700000,
            unit_amount_decimal: '3700000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgjN4B0P8XBvhyKATH2Jpet',
          yearlyFlatFees: '{"eur":37000,"usd":37000,"cad":37000,"chf":37000,"gbp":37000}'
        },
        nickname: 'Flat fee for yearly 3B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 3700000,
        unit_amount_decimal: '3700000'
      },
      {
        id: 'price_1OgjN0B0P8XBvhyKlmoOmnCD',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707205626,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0015',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0015',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0015',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0015',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0015',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgjMzB0P8XBvhyKIgzFFm6D',
          graduatedFirstPaidTierFees: '{"eur":0.000014999999999999999,"usd":0.000014999999999999999,"cad":0.000014999999999999999,"chf":0.000014999999999999999,"gbp":0.000014999999999999999}',
          multiCurrency: 'true',
          services: 'cookies,processings,dpo',
          yearlyFlatFees: '{"eur":26000,"usd":26000,"cad":26000,"chf":26000,"gbp":26000}',
          yearlyMinUsage: '1500000000'
        },
        nickname: 'yearly 1.5B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1500000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0015',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgjMzB0P8XBvhyKIgzFFm6D',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707205625,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2600000,
            unit_amount_decimal: '2600000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2600000,
            unit_amount_decimal: '2600000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2600000,
            unit_amount_decimal: '2600000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2600000,
            unit_amount_decimal: '2600000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2600000,
            unit_amount_decimal: '2600000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgjN0B0P8XBvhyKlmoOmnCD',
          yearlyFlatFees: '{"eur":26000,"usd":26000,"cad":26000,"chf":26000,"gbp":26000}'
        },
        nickname: 'Flat fee for yearly 1.5B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2600000,
        unit_amount_decimal: '2600000'
      },
      {
        id: 'price_1OgjMwB0P8XBvhyKr0Nv1Pap',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707205622,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.002',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.002',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.002',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.002',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.002',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgjMvB0P8XBvhyK8Epfl0LH',
          graduatedFirstPaidTierFees: '{"eur":0.00002,"usd":0.00002,"cad":0.00002,"chf":0.00002,"gbp":0.00002}',
          multiCurrency: 'true',
          services: 'cookies,processings,dpo',
          yearlyFlatFees: '{"eur":22000,"usd":22000,"cad":22000,"chf":22000,"gbp":22000}',
          yearlyMinUsage: '1000000000'
        },
        nickname: 'yearly 1B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1000000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.002',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgjMvB0P8XBvhyK8Epfl0LH',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707205621,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2200000,
            unit_amount_decimal: '2200000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2200000,
            unit_amount_decimal: '2200000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2200000,
            unit_amount_decimal: '2200000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2200000,
            unit_amount_decimal: '2200000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2200000,
            unit_amount_decimal: '2200000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgjMwB0P8XBvhyKr0Nv1Pap',
          yearlyFlatFees: '{"eur":22000,"usd":22000,"cad":22000,"chf":22000,"gbp":22000}'
        },
        nickname: 'Flat fee for yearly 1B page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2200000,
        unit_amount_decimal: '2200000'
      },
      {
        id: 'price_1OgjMsB0P8XBvhyK7ZccYxxn',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707205618,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0026',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0026',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0026',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0026',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0026',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgjMrB0P8XBvhyK6RPXT8lF',
          graduatedFirstPaidTierFees: '{"eur":0.000026,"usd":0.000026,"cad":0.000026,"chf":0.000026,"gbp":0.000026}',
          multiCurrency: 'true',
          services: 'cookies,processings,dpo',
          yearlyFlatFees: '{"eur":15000,"usd":15000,"cad":15000,"chf":15000,"gbp":15000}',
          yearlyMinUsage: '500000000'
        },
        nickname: 'yearly 500M page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 500000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0026',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgjMrB0P8XBvhyK6RPXT8lF',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707205617,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgjMsB0P8XBvhyK7ZccYxxn',
          yearlyFlatFees: '{"eur":15000,"usd":15000,"cad":15000,"chf":15000,"gbp":15000}'
        },
        nickname: 'Flat fee for yearly 500M page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500000,
        unit_amount_decimal: '1500000'
      },
      {
        id: 'price_1OgjMoB0P8XBvhyKO8CFHD4V',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707205614,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.004',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.004',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.004',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.004',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.004',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgjMnB0P8XBvhyK6ISE5zbZ',
          graduatedFirstPaidTierFees: '{"eur":0.00004,"usd":0.00004,"cad":0.00004,"chf":0.00004,"gbp":0.00004}',
          multiCurrency: 'true',
          services: 'cookies,processings,dpo',
          yearlyFlatFees: '{"eur":10000,"usd":10000,"cad":10000,"chf":10000,"gbp":10000}',
          yearlyMinUsage: '200000000'
        },
        nickname: 'yearly 200M page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 200000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.004',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgjMnB0P8XBvhyK6ISE5zbZ',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707205613,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgjMoB0P8XBvhyKO8CFHD4V',
          yearlyFlatFees: '{"eur":10000,"usd":10000,"cad":10000,"chf":10000,"gbp":10000}'
        },
        nickname: 'Flat fee for yearly 200M page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1000000,
        unit_amount_decimal: '1000000'
      },
      {
        id: 'price_1OgjMkB0P8XBvhyKBDmYtXLl',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707205610,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0065',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgjMjB0P8XBvhyKDWCA8HCC',
          graduatedFirstPaidTierFees: '{"eur":0.00006500000000000001,"usd":0.00006500000000000001,"cad":0.00006500000000000001,"chf":0.00006500000000000001,"gbp":0.00006500000000000001}',
          multiCurrency: 'true',
          services: 'cookies,processings,dpo',
          yearlyFlatFees: '{"eur":8000,"usd":8000,"cad":8000,"chf":8000,"gbp":8000}',
          yearlyMinUsage: '100000000'
        },
        nickname: 'yearly 100M page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 100000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0065',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgjMjB0P8XBvhyKDWCA8HCC',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707205609,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgjMkB0P8XBvhyKBDmYtXLl',
          yearlyFlatFees: '{"eur":8000,"usd":8000,"cad":8000,"chf":8000,"gbp":8000}'
        },
        nickname: 'Flat fee for yearly 100M page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 800000,
        unit_amount_decimal: '800000'
      },
      {
        id: 'price_1OgjMfB0P8XBvhyKaA3mbXrZ',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707205605,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgjMeB0P8XBvhyKEUwx0LbR',
          graduatedFirstPaidTierFees: '{"eur":0.00011,"usd":0.00011,"cad":0.00011,"chf":0.00011,"gbp":0.00011}',
          multiCurrency: 'true',
          services: 'cookies,processings,dpo',
          yearlyFlatFees: '{"eur":7000,"usd":7000,"cad":7000,"chf":7000,"gbp":7000}',
          yearlyMinUsage: '50000000'
        },
        nickname: 'yearly 50M page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 50000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.011',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgjMeB0P8XBvhyKEUwx0LbR',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707205604,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 700000,
            unit_amount_decimal: '700000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 700000,
            unit_amount_decimal: '700000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 700000,
            unit_amount_decimal: '700000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 700000,
            unit_amount_decimal: '700000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 700000,
            unit_amount_decimal: '700000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgjMfB0P8XBvhyKaA3mbXrZ',
          yearlyFlatFees: '{"eur":7000,"usd":7000,"cad":7000,"chf":7000,"gbp":7000}'
        },
        nickname: 'Flat fee for yearly 50M page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 700000,
        unit_amount_decimal: '700000'
      },
      {
        id: 'price_1OgjMbB0P8XBvhyKczcW637w',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707205601,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.019',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.019',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.019',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.019',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.019',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgjMaB0P8XBvhyKLfL9K69b',
          graduatedFirstPaidTierFees: '{"eur":0.00019,"usd":0.00019,"cad":0.00019,"chf":0.00019,"gbp":0.00019}',
          multiCurrency: 'true',
          services: 'cookies,processings,dpo',
          yearlyFlatFees: '{"eur":6000,"usd":6000,"cad":6000,"chf":6000,"gbp":6000}',
          yearlyMinUsage: '25000000'
        },
        nickname: 'yearly 25M page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 25000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.019',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgjMaB0P8XBvhyKLfL9K69b',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707205600,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 600000,
            unit_amount_decimal: '600000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 600000,
            unit_amount_decimal: '600000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 600000,
            unit_amount_decimal: '600000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 600000,
            unit_amount_decimal: '600000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 600000,
            unit_amount_decimal: '600000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgjMbB0P8XBvhyKczcW637w',
          yearlyFlatFees: '{"eur":6000,"usd":6000,"cad":6000,"chf":6000,"gbp":6000}'
        },
        nickname: 'Flat fee for yearly 25M page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 600000,
        unit_amount_decimal: '600000'
      },
      {
        id: 'price_1OgjMXB0P8XBvhyKyPGjUBLE',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1707205597,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.032',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contextual Consent Wall • Complete Analytics • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consent Wall Contextuel • Stats complètes • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OgjMXB0P8XBvhyK8FSzWLZX',
          graduatedFirstPaidTierFees: '{"eur":0.00032,"usd":0.00032,"cad":0.00032,"chf":0.00032,"gbp":0.00032}',
          multiCurrency: 'true',
          services: 'cookies,processings,dpo',
          yearlyFlatFees: '{"eur":4800,"usd":4800,"cad":4800,"chf":4800,"gbp":4800}',
          yearlyMinUsage: '12000000'
        },
        nickname: 'yearly 12M page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 12000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.032',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OgjMXB0P8XBvhyK8FSzWLZX',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1707205597,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 480000,
            unit_amount_decimal: '480000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 480000,
            unit_amount_decimal: '480000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 480000,
            unit_amount_decimal: '480000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 480000,
            unit_amount_decimal: '480000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 480000,
            unit_amount_decimal: '480000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OgjMXB0P8XBvhyKyPGjUBLE',
          yearlyFlatFees: '{"eur":4800,"usd":4800,"cad":4800,"chf":4800,"gbp":4800}'
        },
        nickname: 'Flat fee for yearly 12M page views/year',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 480000,
        unit_amount_decimal: '480000'
      },
      {
        id: 'price_1LPhwLB0P8XBvhyKBZL16yAp',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819545,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2400000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0023',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhwLB0P8XBvhyK1e7iNIlz',
          monthlyFlatFee: '4506',
          monthlyMinUsage: '200000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 200M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 2400000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0023',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhwLB0P8XBvhyK1e7iNIlz',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819545,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4506000,
            unit_amount_decimal: '4506000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhwLB0P8XBvhyKBZL16yAp'
        },
        nickname: 'Flat fee for yearly 200M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4506000,
        unit_amount_decimal: '4506000'
      },
      {
        id: 'price_1LPhwIB0P8XBvhyKmXMMAOfh',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819542,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2160000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0024',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhwIB0P8XBvhyKkKqJskCI',
          monthlyFlatFee: '4406',
          monthlyMinUsage: '180000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 180M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 2160000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0024',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhwIB0P8XBvhyKkKqJskCI',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819542,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4406000,
            unit_amount_decimal: '4406000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhwIB0P8XBvhyKmXMMAOfh'
        },
        nickname: 'Flat fee for yearly 180M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4406000,
        unit_amount_decimal: '4406000'
      },
      {
        id: 'price_1LPhwEB0P8XBvhyKdU189qkj',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819538,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1920000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0027',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhwEB0P8XBvhyKlpxlo8R6',
          monthlyFlatFee: '4259',
          monthlyMinUsage: '160000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 160M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1920000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0027',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhwEB0P8XBvhyKlpxlo8R6',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819538,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4259000,
            unit_amount_decimal: '4259000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhwEB0P8XBvhyKdU189qkj'
        },
        nickname: 'Flat fee for yearly 160M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4259000,
        unit_amount_decimal: '4259000'
      },
      {
        id: 'price_1LPhwBB0P8XBvhyKx0glqysr',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819535,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1680000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0029',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhwBB0P8XBvhyKp6wLQ0t4',
          monthlyFlatFee: '4050',
          monthlyMinUsage: '140000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 140M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1680000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0029',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhwBB0P8XBvhyKp6wLQ0t4',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819535,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4050000,
            unit_amount_decimal: '4050000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhwBB0P8XBvhyKx0glqysr'
        },
        nickname: 'Flat fee for yearly 140M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4050000,
        unit_amount_decimal: '4050000'
      },
      {
        id: 'price_1LPhw7B0P8XBvhyK2pS7Rftk',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819531,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1440000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0031',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhw6B0P8XBvhyKbvd41tlg',
          monthlyFlatFee: '3774',
          monthlyMinUsage: '120000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 120M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1440000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0031',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhw6B0P8XBvhyKbvd41tlg',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819530,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 3774000,
            unit_amount_decimal: '3774000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhw7B0P8XBvhyK2pS7Rftk'
        },
        nickname: 'Flat fee for yearly 120M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 3774000,
        unit_amount_decimal: '3774000'
      },
      {
        id: 'price_1LPhw4B0P8XBvhyKHkyuG9CS',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819528,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1200000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0034',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhw3B0P8XBvhyKNiOeraS4',
          monthlyFlatFee: '3429',
          monthlyMinUsage: '100000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 100M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1200000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0034',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhw3B0P8XBvhyKNiOeraS4',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819527,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 3429000,
            unit_amount_decimal: '3429000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhw4B0P8XBvhyKHkyuG9CS'
        },
        nickname: 'Flat fee for yearly 100M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 3429000,
        unit_amount_decimal: '3429000'
      },
      {
        id: 'price_1LPhw0B0P8XBvhyKB1DhI4TI',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819524,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 960000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.004',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhvzB0P8XBvhyKcYluuVTW',
          monthlyFlatFee: '3199',
          monthlyMinUsage: '80000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 80M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 960000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.004',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhvzB0P8XBvhyKcYluuVTW',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819523,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 3199000,
            unit_amount_decimal: '3199000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhw0B0P8XBvhyKB1DhI4TI'
        },
        nickname: 'Flat fee for yearly 80M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 3199000,
        unit_amount_decimal: '3199000'
      },
      {
        id: 'price_1LPhvxB0P8XBvhyKMsJYniNw',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819521,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 840000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.004',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhvwB0P8XBvhyKKpN0GbFc',
          monthlyFlatFee: '2827',
          monthlyMinUsage: '70000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 70M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 840000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.004',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhvwB0P8XBvhyKKpN0GbFc',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819520,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2827000,
            unit_amount_decimal: '2827000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhvxB0P8XBvhyKMsJYniNw'
        },
        nickname: 'Flat fee for  yearly 70M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2827000,
        unit_amount_decimal: '2827000'
      },
      {
        id: 'price_1LPhvuB0P8XBvhyKOF6NtHZi',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819518,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 600000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0044',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhvtB0P8XBvhyKD41LwB8Z',
          monthlyFlatFee: '2195',
          monthlyMinUsage: '50000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 50M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 600000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0044',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhvtB0P8XBvhyKD41LwB8Z',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819517,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2195000,
            unit_amount_decimal: '2195000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhvuB0P8XBvhyKOF6NtHZi'
        },
        nickname: 'Flat fee for yearly 50M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2195000,
        unit_amount_decimal: '2195000'
      },
      {
        id: 'price_1LPhvqB0P8XBvhyK2xrC6vXs',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819514,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 480000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0048',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhvqB0P8XBvhyKV6URHeFc',
          monthlyFlatFee: '1908',
          monthlyMinUsage: '40000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 40M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 480000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0048',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhvqB0P8XBvhyKV6URHeFc',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819514,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1908000,
            unit_amount_decimal: '1908000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhvqB0P8XBvhyK2xrC6vXs'
        },
        nickname: 'Flat fee for yearly 40M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1908000,
        unit_amount_decimal: '1908000'
      },
      {
        id: 'price_1LPhvnB0P8XBvhyKk2LfquVW',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819511,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 360000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0052',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhvmB0P8XBvhyK8vVKHMp8',
          monthlyFlatFee: '1556',
          monthlyMinUsage: '30000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 30M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 360000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0052',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhvmB0P8XBvhyK8vVKHMp8',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819510,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1556000,
            unit_amount_decimal: '1556000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhvnB0P8XBvhyKk2LfquVW'
        },
        nickname: 'Flat fee for yearly 30M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1556000,
        unit_amount_decimal: '1556000'
      },
      {
        id: 'price_1LPhvjB0P8XBvhyK9w25EkrD',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819507,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 300000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0056',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhvjB0P8XBvhyKEIqiOKYG',
          monthlyFlatFee: '1409',
          monthlyMinUsage: '25000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 25M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 300000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0056',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhvjB0P8XBvhyKEIqiOKYG',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819507,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1409000,
            unit_amount_decimal: '1409000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhvjB0P8XBvhyK9w25EkrD'
        },
        nickname: 'Flat fee for yearly 25M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1409000,
        unit_amount_decimal: '1409000'
      },
      {
        id: 'price_1LPhvfB0P8XBvhyKVZx7iu9E',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819503,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 240000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0061',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhveB0P8XBvhyK2xJc5VAo',
          monthlyFlatFee: '1225',
          monthlyMinUsage: '20000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 20M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 240000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0061',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhveB0P8XBvhyK2xJc5VAo',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819502,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1225000,
            unit_amount_decimal: '1225000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhvfB0P8XBvhyKVZx7iu9E'
        },
        nickname: 'Flat fee for yearly 20M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1225000,
        unit_amount_decimal: '1225000'
      },
      {
        id: 'price_1LPhvcB0P8XBvhyKuarSO2bp',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819500,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 180000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0067',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhvcB0P8XBvhyK6rKN28Zz',
          monthlyFlatFee: '999',
          monthlyMinUsage: '15000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 15M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 180000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0067',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhvcB0P8XBvhyK6rKN28Zz',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819500,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 999000,
            unit_amount_decimal: '999000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhvcB0P8XBvhyKuarSO2bp'
        },
        nickname: 'Flat fee for yearly 15M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 999000,
        unit_amount_decimal: '999000'
      },
      {
        id: 'price_1LPhvZB0P8XBvhyKx6xuPoRd',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819497,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 120000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.008',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhvYB0P8XBvhyKtcsGUdz6',
          monthlyFlatFee: '799',
          monthlyMinUsage: '10000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 10M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 120000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.008',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhvYB0P8XBvhyKtcsGUdz6',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819496,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 799000,
            unit_amount_decimal: '799000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhvZB0P8XBvhyKx6xuPoRd'
        },
        nickname: 'Flat fee for yearly 10M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 799000,
        unit_amount_decimal: '799000'
      },
      {
        id: 'price_1LPhvVB0P8XBvhyKO54Umi4G',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819493,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 84000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.01',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhvVB0P8XBvhyKWrhmYYTn',
          monthlyFlatFee: '699',
          monthlyMinUsage: '7000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 7M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 84000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.01',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhvVB0P8XBvhyKWrhmYYTn',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819493,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 699000,
            unit_amount_decimal: '699000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhvVB0P8XBvhyKO54Umi4G'
        },
        nickname: 'Flat fee for yearly 7M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 699000,
        unit_amount_decimal: '699000'
      },
      {
        id: 'price_1LPhvSB0P8XBvhyKuIm45Yx2',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819490,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 72000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.01',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhvSB0P8XBvhyKV0NZZGgn',
          monthlyFlatFee: '599',
          monthlyMinUsage: '6000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 6M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 72000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.01',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhvSB0P8XBvhyKV0NZZGgn',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819490,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 599000,
            unit_amount_decimal: '599000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhvSB0P8XBvhyKuIm45Yx2'
        },
        nickname: 'Flat fee for yearly 6M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 599000,
        unit_amount_decimal: '599000'
      },
      {
        id: 'price_1LPhvPB0P8XBvhyKqRIoaDoX',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819487,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.011',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhvPB0P8XBvhyKKnSg2BJ8',
          monthlyFlatFee: '549',
          monthlyMinUsage: '5000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 5M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 60000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.011',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhvPB0P8XBvhyKKnSg2BJ8',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819487,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 549000,
            unit_amount_decimal: '549000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhvPB0P8XBvhyKqRIoaDoX'
        },
        nickname: 'Flat fee for yearly 5M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 549000,
        unit_amount_decimal: '549000'
      },
      {
        id: 'price_1LPhvLB0P8XBvhyKYIgQxuMN',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819483,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 36000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.015',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhvLB0P8XBvhyKqx0xzEPf',
          monthlyFlatFee: '449',
          monthlyMinUsage: '3000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 3M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 36000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.015',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhvLB0P8XBvhyKqx0xzEPf',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819483,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 449000,
            unit_amount_decimal: '449000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhvLB0P8XBvhyKYIgQxuMN'
        },
        nickname: 'Flat fee for yearly 3M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 449000,
        unit_amount_decimal: '449000'
      },
      {
        id: 'price_1LPhvJB0P8XBvhyKCtUJxfvo',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819481,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 24000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0175',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhvIB0P8XBvhyKZfRj2YBX',
          monthlyFlatFee: '349',
          monthlyMinUsage: '2000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 2M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 24000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0175',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhvIB0P8XBvhyKZfRj2YBX',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819480,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 349000,
            unit_amount_decimal: '349000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhvJB0P8XBvhyKCtUJxfvo'
        },
        nickname: 'Flat fee for yearly 2M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 349000,
        unit_amount_decimal: '349000'
      },
      {
        id: 'price_1LPhvGB0P8XBvhyKdKbHEdAn',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819478,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 18000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0199',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhvFB0P8XBvhyKKwd88HTU',
          monthlyFlatFee: '299',
          monthlyMinUsage: '1500000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 1,5M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 18000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0199',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhvFB0P8XBvhyKKwd88HTU',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819477,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 299000,
            unit_amount_decimal: '299000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhvGB0P8XBvhyKdKbHEdAn'
        },
        nickname: 'Flat fee for yearly 1,5M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 299000,
        unit_amount_decimal: '299000'
      },
      {
        id: 'price_1LPhvCB0P8XBvhyKo62FZvzZ',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819474,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0249',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhvCB0P8XBvhyKGodCehP4',
          monthlyFlatFee: '249',
          monthlyMinUsage: '1000000',
          services: 'cookies,processings,contracts,dpo,portability'
        },
        nickname: 'yearly 1M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 12000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0249',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhvCB0P8XBvhyKGodCehP4',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819474,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 249000,
            unit_amount_decimal: '249000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited domains & projects • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Complete Analytics • Mobile SDK • email support • Dedicated CSM • Payment by bank transfer',
          description_FR: 'Domaines et projets illimités • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats complètes • SDK mobile • Support tel/mail • CSM dédié • Paiement par virement',
          features: 'customization, consentWall, mobileSDK, cookiesConfigTranslation',
          services: 'cookies,processings,contracts,dpo,portability',
          tieredPriceId: 'price_1LPhvCB0P8XBvhyKo62FZvzZ'
        },
        nickname: 'Flat fee for yearly 1M page views/month',
        product: 'prod_enterpriseMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 249000,
        unit_amount_decimal: '249000'
      }
    ]
  },
  {
    id: 'prod_appSdk',
    metadata: {
      WARNING: 'DO NOT EDIT METADATA !!! 🙏',
      features: 'customization,appSdkTcf',
      label: 'App SDK TCF',
      meteredCriteria: 'monthlyActiveUsers',
      statsAllowedAggregateBy: 'day,week,month,year',
      statsAreFiltersAllowed: 'true',
      statsKpi: 'appSdkUsers,appSdkNewUsers,appSdkInteractionRate,appSdkConsentRate,appSdkOptInRate,appSdkQuickBounceRate',
      statsPeriod: 'lastYear',
      statsWidgets: 'appSdkDailyConsentsOverview,appSdkDailyConsentsRates,appSdkDailyUsersOverview',
      title_EN: 'App SDK TCF',
      title_FR: 'SDK App TCF',
      zd_priority: 'high'
    },
    active: true,
    name: 'App SDK TCF',
    type: 'service',
    prices: [
      {
        id: 'price_1PXewhB0P8XBvhyK324Z7tAP',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1719820003,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2400000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 1,
                unit_amount_decimal: '1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2400000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 1,
                unit_amount_decimal: '1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2400000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 1,
                unit_amount_decimal: '1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2400000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 1,
                unit_amount_decimal: '1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2400000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 1,
                unit_amount_decimal: '1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1PXewgB0P8XBvhyKEsCH7peI',
          graduatedFirstPaidTierFees: '{"eur":0.01,"usd":0.01,"cad":0.01,"chf":0.01,"gbp":0.01}',
          label: 'App SDK',
          meteredCriteria: 'monthlyActiveUsers',
          monthlyActiveUsers: '200000',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":2500,"usd":2500,"cad":2500,"chf":2500,"gbp":2500}',
          yearlyMinUsage: '2400000'
        },
        nickname: 'yearly : 200K monthly active users',
        product: 'prod_appSdk',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 2400000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 1,
            unit_amount_decimal: '1',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1PXewgB0P8XBvhyKEsCH7peI',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1719820002,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 250000,
            unit_amount_decimal: '250000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 250000,
            unit_amount_decimal: '250000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 250000,
            unit_amount_decimal: '250000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 250000,
            unit_amount_decimal: '250000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 250000,
            unit_amount_decimal: '250000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1PXewhB0P8XBvhyK324Z7tAP',
          yearlyFlatFees: '{"eur":2500,"usd":2500,"cad":2500,"chf":2500,"gbp":2500}'
        },
        nickname: 'Flat fee for yearly : 200K monthly active users',
        product: 'prod_appSdk',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 250000,
        unit_amount_decimal: '250000'
      },
      {
        id: 'price_1P957UB0P8XBvhyKkKaw7drg',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713962416,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 600000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 600000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 600000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 600000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 600000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P957TB0P8XBvhyKgggKwQ8S',
          graduatedFirstPaidTierFees: '{"eur":0.0005,"usd":0.0005,"cad":0.0005,"chf":0.0005,"gbp":0.0005}',
          label: 'App SDK',
          meteredCriteria: 'monthlyActiveUsers',
          monthlyActiveUsers: '50000000',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":40000,"usd":40000,"cad":40000,"chf":40000,"gbp":40000}',
          yearlyMinUsage: '600000000'
        },
        nickname: 'yearly : 50M monthly active users',
        product: 'prod_appSdk',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 600000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.05',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P957TB0P8XBvhyKgggKwQ8S',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713962415,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4000000,
            unit_amount_decimal: '4000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4000000,
            unit_amount_decimal: '4000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4000000,
            unit_amount_decimal: '4000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4000000,
            unit_amount_decimal: '4000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4000000,
            unit_amount_decimal: '4000000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P957UB0P8XBvhyKkKaw7drg',
          yearlyFlatFees: '{"eur":40000,"usd":40000,"cad":40000,"chf":40000,"gbp":40000}'
        },
        nickname: 'Flat fee for yearly : 50M monthly active users',
        product: 'prod_appSdk',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4000000,
        unit_amount_decimal: '4000000'
      },
      {
        id: 'price_1P957RB0P8XBvhyK1jPAX4wV',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713962413,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 240000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 240000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 240000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 240000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 240000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P957PB0P8XBvhyKPrpuO012',
          graduatedFirstPaidTierFees: '{"eur":0.001,"usd":0.001,"cad":0.001,"chf":0.001,"gbp":0.001}',
          label: 'App SDK',
          meteredCriteria: 'monthlyActiveUsers',
          monthlyActiveUsers: '20000000',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":25000,"usd":25000,"cad":25000,"chf":25000,"gbp":25000}',
          yearlyMinUsage: '240000000'
        },
        nickname: 'yearly : 20M monthly active users',
        product: 'prod_appSdk',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 240000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.1',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P957PB0P8XBvhyKPrpuO012',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713962411,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2500000,
            unit_amount_decimal: '2500000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2500000,
            unit_amount_decimal: '2500000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2500000,
            unit_amount_decimal: '2500000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2500000,
            unit_amount_decimal: '2500000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2500000,
            unit_amount_decimal: '2500000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P957RB0P8XBvhyK1jPAX4wV',
          yearlyFlatFees: '{"eur":25000,"usd":25000,"cad":25000,"chf":25000,"gbp":25000}'
        },
        nickname: 'Flat fee for yearly : 20M monthly active users',
        product: 'prod_appSdk',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2500000,
        unit_amount_decimal: '2500000'
      },
      {
        id: 'price_1P957NB0P8XBvhyKz814llhl',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713962409,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 120000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 120000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 120000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 120000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 120000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P957LB0P8XBvhyKjUegqti7',
          graduatedFirstPaidTierFees: '{"eur":0.0015,"usd":0.0015,"cad":0.0015,"chf":0.0015,"gbp":0.0015}',
          label: 'App SDK',
          meteredCriteria: 'monthlyActiveUsers',
          monthlyActiveUsers: '10000000',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":20000,"usd":20000,"cad":20000,"chf":20000,"gbp":20000}',
          yearlyMinUsage: '120000000'
        },
        nickname: 'yearly : 10M monthly active users',
        product: 'prod_appSdk',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 120000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.15',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P957LB0P8XBvhyKjUegqti7',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713962407,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P957NB0P8XBvhyKz814llhl',
          yearlyFlatFees: '{"eur":20000,"usd":20000,"cad":20000,"chf":20000,"gbp":20000}'
        },
        nickname: 'Flat fee for yearly : 10M monthly active users',
        product: 'prod_appSdk',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2000000,
        unit_amount_decimal: '2000000'
      },
      {
        id: 'price_1P957KB0P8XBvhyKTnAbB34K',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713962406,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.25',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.25',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.25',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.25',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.25',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P957IB0P8XBvhyKBWExN3An',
          graduatedFirstPaidTierFees: '{"eur":0.0025,"usd":0.0025,"cad":0.0025,"chf":0.0025,"gbp":0.0025}',
          label: 'App SDK',
          meteredCriteria: 'monthlyActiveUsers',
          monthlyActiveUsers: '5000000',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":15000,"usd":15000,"cad":15000,"chf":15000,"gbp":15000}',
          yearlyMinUsage: '60000000'
        },
        nickname: 'yearly : 5M monthly active users',
        product: 'prod_appSdk',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 60000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.25',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P957IB0P8XBvhyKBWExN3An',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713962404,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P957KB0P8XBvhyKTnAbB34K',
          yearlyFlatFees: '{"eur":15000,"usd":15000,"cad":15000,"chf":15000,"gbp":15000}'
        },
        nickname: 'Flat fee for yearly : 5M monthly active users',
        product: 'prod_appSdk',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500000,
        unit_amount_decimal: '1500000'
      },
      {
        id: 'price_1P957GB0P8XBvhyK79GVDo5g',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713962402,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 18000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.5',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 18000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.5',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 18000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.5',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 18000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.5',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 18000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.5',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P957EB0P8XBvhyK5GASbvdz',
          graduatedFirstPaidTierFees: '{"eur":0.005,"usd":0.005,"cad":0.005,"chf":0.005,"gbp":0.005}',
          label: 'App SDK',
          meteredCriteria: 'monthlyActiveUsers',
          monthlyActiveUsers: '1500000',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":10000,"usd":10000,"cad":10000,"chf":10000,"gbp":10000}',
          yearlyMinUsage: '18000000'
        },
        nickname: 'yearly : 1.5M monthly active users',
        product: 'prod_appSdk',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 18000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.5',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P957EB0P8XBvhyK5GASbvdz',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713962400,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000000,
            unit_amount_decimal: '1000000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P957GB0P8XBvhyK79GVDo5g',
          yearlyFlatFees: '{"eur":10000,"usd":10000,"cad":10000,"chf":10000,"gbp":10000}'
        },
        nickname: 'Flat fee for yearly : 1.5M monthly active users',
        product: 'prod_appSdk',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1000000,
        unit_amount_decimal: '1000000'
      },
      {
        id: 'price_1P957BB0P8XBvhyKMf37nsVY',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713962397,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.7',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.7',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.7',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.7',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 12000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.7',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P9579B0P8XBvhyKTulMkIFH',
          graduatedFirstPaidTierFees: '{"eur":0.007,"usd":0.007,"cad":0.007,"chf":0.007,"gbp":0.007}',
          label: 'App SDK',
          meteredCriteria: 'monthlyActiveUsers',
          monthlyActiveUsers: '1000000',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":7500,"usd":7500,"cad":7500,"chf":7500,"gbp":7500}',
          yearlyMinUsage: '12000000'
        },
        nickname: 'yearly : 1M monthly active users',
        product: 'prod_appSdk',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 12000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.7',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P9579B0P8XBvhyKTulMkIFH',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713962395,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 750000,
            unit_amount_decimal: '750000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 750000,
            unit_amount_decimal: '750000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 750000,
            unit_amount_decimal: '750000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 750000,
            unit_amount_decimal: '750000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 750000,
            unit_amount_decimal: '750000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P957BB0P8XBvhyKMf37nsVY',
          yearlyFlatFees: '{"eur":7500,"usd":7500,"cad":7500,"chf":7500,"gbp":7500}'
        },
        nickname: 'Flat fee for yearly : 1M monthly active users',
        product: 'prod_appSdk',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 750000,
        unit_amount_decimal: '750000'
      },
      {
        id: 'price_1P9578B0P8XBvhyKMxfmXxTA',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713962394,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.875',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.875',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.875',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.875',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.875',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P9576B0P8XBvhyKUJmO2Uzm',
          graduatedFirstPaidTierFees: '{"eur":0.00875,"usd":0.00875,"cad":0.00875,"chf":0.00875,"gbp":0.00875}',
          label: 'App SDK',
          meteredCriteria: 'monthlyActiveUsers',
          monthlyActiveUsers: '500000',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":5000,"usd":5000,"cad":5000,"chf":5000,"gbp":5000}',
          yearlyMinUsage: '6000000'
        },
        nickname: 'yearly : 500K monthly active users',
        product: 'prod_appSdk',
        recurring: {
          aggregate_usage: 'last_during_period',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 6000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.875',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P9576B0P8XBvhyKUJmO2Uzm',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713962392,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P9578B0P8XBvhyKMxfmXxTA',
          yearlyFlatFees: '{"eur":5000,"usd":5000,"cad":5000,"chf":5000,"gbp":5000}'
        },
        nickname: 'Flat fee for yearly : 500K monthly active users',
        product: 'prod_appSdk',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 500000,
        unit_amount_decimal: '500000'
      }
    ]
  },
  {
    id: 'prod_terms',
    metadata: {
      WARNING: 'DO NOT EDIT METADATA !!! 🙏',
      meteredCriteria: 'termsConsents',
      services: 'contractsV2',
      title_EN: 'Contractual Consent Management (Terms)',
      title_FR: 'Recueil des consentements contractuels (Terms)',
      zd_priority: 'high'
    },
    active: true,
    name: 'Contractual Consent Management (Terms)',
    type: 'service',
    prices: [
      {
        id: 'price_1PTMhpB0P8XBvhyKXamP2AGE',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1718796577,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1PTMhpB0P8XBvhyKyUjfYoqc',
          graduatedFirstPaidTierFees: '{"eur":0.0003,"usd":0.0003,"cad":0.0003,"chf":0.0003,"gbp":0.0003}',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":21000,"usd":21000,"cad":21000,"chf":21000,"gbp":21000}',
          yearlyMinUsage: '25000000'
        },
        nickname: 'yearly Terms : 25M consents/year (Comutitres)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 0,
            flat_amount_decimal: '0',
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 25000000
          },
          {
            flat_amount: 0,
            flat_amount_decimal: '0',
            unit_amount: null,
            unit_amount_decimal: '0.03',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1PTMhpB0P8XBvhyKyUjfYoqc',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1718796577,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2100000,
            unit_amount_decimal: '2100000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2100000,
            unit_amount_decimal: '2100000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2100000,
            unit_amount_decimal: '2100000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2100000,
            unit_amount_decimal: '2100000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2100000,
            unit_amount_decimal: '2100000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1PTMhpB0P8XBvhyKXamP2AGE',
          yearlyFlatFees: '{"eur":21000,"usd":21000,"cad":21000,"chf":21000,"gbp":21000}'
        },
        nickname: 'Flat fee for yearly Terms : 25M consents/year (Comutitres)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2100000,
        unit_amount_decimal: '2100000'
      },
      {
        id: 'price_1P96FGB0P8XBvhyK4tHjfXb6',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713966742,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25000000
              },
              {
                flat_amount: 0,
                flat_amount_decimal: '0',
                unit_amount: null,
                unit_amount_decimal: '0.03',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P96FEB0P8XBvhyKWuydfuyF',
          graduatedFirstPaidTierFees: '{"eur":0.0003,"usd":0.0003,"cad":0.0003,"chf":0.0003,"gbp":0.0003}',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":20000,"usd":20000,"cad":20000,"chf":20000,"gbp":20000}',
          yearlyMinUsage: '25000000'
        },
        nickname: 'yearly Terms : 25M consents/year',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: 0,
            flat_amount_decimal: '0',
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 25000000
          },
          {
            flat_amount: 0,
            flat_amount_decimal: '0',
            unit_amount: null,
            unit_amount_decimal: '0.03',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P96FEB0P8XBvhyKWuydfuyF',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713966740,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2000000,
            unit_amount_decimal: '2000000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P96FGB0P8XBvhyK4tHjfXb6',
          yearlyFlatFees: '{"eur":20000,"usd":20000,"cad":20000,"chf":20000,"gbp":20000}'
        },
        nickname: 'Flat fee for yearly Terms : 25M consents/year',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2000000,
        unit_amount_decimal: '2000000'
      },
      {
        id: 'price_1P96FBB0P8XBvhyK6XKFGbi8',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713966737,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 15000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.05',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P96FAB0P8XBvhyKAgCvSXkB',
          graduatedFirstPaidTierFees: '{"eur":0.0005,"usd":0.0005,"cad":0.0005,"chf":0.0005,"gbp":0.0005}',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":15000,"usd":15000,"cad":15000,"chf":15000,"gbp":15000}',
          yearlyMinUsage: '15000000'
        },
        nickname: 'yearly Terms : 15M consents/year',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 15000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.05',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P96FAB0P8XBvhyKAgCvSXkB',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713966736,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500000,
            unit_amount_decimal: '1500000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P96FBB0P8XBvhyK6XKFGbi8',
          yearlyFlatFees: '{"eur":15000,"usd":15000,"cad":15000,"chf":15000,"gbp":15000}'
        },
        nickname: 'Flat fee for yearly Terms : 15M consents/year',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500000,
        unit_amount_decimal: '1500000'
      },
      {
        id: 'price_1P96F8B0P8XBvhyKeECnieVz',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713966734,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.07',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P96F6B0P8XBvhyKzYO4yG6O',
          graduatedFirstPaidTierFees: '{"eur":0.0007,"usd":0.0007,"cad":0.0007,"chf":0.0007,"gbp":0.0007}',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":12000,"usd":12000,"cad":12000,"chf":12000,"gbp":12000}',
          yearlyMinUsage: '8000000'
        },
        nickname: 'yearly Terms : 8M consents/year',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 8000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.07',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P96F6B0P8XBvhyKzYO4yG6O',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713966732,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1200000,
            unit_amount_decimal: '1200000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P96F8B0P8XBvhyKeECnieVz',
          yearlyFlatFees: '{"eur":12000,"usd":12000,"cad":12000,"chf":12000,"gbp":12000}'
        },
        nickname: 'Flat fee for yearly Terms : 8M consents/year',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1200000,
        unit_amount_decimal: '1200000'
      },
      {
        id: 'price_1P96F4B0P8XBvhyKJf1RIudR',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713966730,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P96F2B0P8XBvhyKW5aPL4zQ',
          graduatedFirstPaidTierFees: '{"eur":0.001,"usd":0.001,"cad":0.001,"chf":0.001,"gbp":0.001}',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":8000,"usd":8000,"cad":8000,"chf":8000,"gbp":8000}',
          yearlyMinUsage: '4000000'
        },
        nickname: 'yearly Terms : 4M consents/year',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 4000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.1',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P96F2B0P8XBvhyKW5aPL4zQ',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713966728,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 800000,
            unit_amount_decimal: '800000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P96F4B0P8XBvhyKJf1RIudR',
          yearlyFlatFees: '{"eur":8000,"usd":8000,"cad":8000,"chf":8000,"gbp":8000}'
        },
        nickname: 'Flat fee for yearly Terms : 4M consents/year',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 800000,
        unit_amount_decimal: '800000'
      },
      {
        id: 'price_1P96EzB0P8XBvhyKpAPJEh2a',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713966725,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.15',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P96EyB0P8XBvhyKO4QI8Z6c',
          graduatedFirstPaidTierFees: '{"eur":0.0015,"usd":0.0015,"cad":0.0015,"chf":0.0015,"gbp":0.0015}',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":5000,"usd":5000,"cad":5000,"chf":5000,"gbp":5000}',
          yearlyMinUsage: '2000000'
        },
        nickname: 'yearly Terms : 2M consents/year',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 2000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.15',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P96EyB0P8XBvhyKO4QI8Z6c',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713966724,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 500000,
            unit_amount_decimal: '500000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P96EzB0P8XBvhyKpAPJEh2a',
          yearlyFlatFees: '{"eur":5000,"usd":5000,"cad":5000,"chf":5000,"gbp":5000}'
        },
        nickname: 'Flat fee for yearly Terms : 2M consents/year',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 500000,
        unit_amount_decimal: '500000'
      },
      {
        id: 'price_1P96EwB0P8XBvhyKUhq78V7F',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713966722,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.2',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          flatFeePriceId: 'price_1P96EwB0P8XBvhyKq6bI6ajs',
          graduatedFirstPaidTierFees: '{"eur":0.002,"usd":0.002,"cad":0.002,"chf":0.002,"gbp":0.002}',
          label: 'Terms',
          multiCurrency: 'true',
          yearlyFlatFees: '{"eur":3000,"usd":3000,"cad":3000,"chf":3000,"gbp":3000}',
          yearlyMinUsage: '1000000'
        },
        nickname: 'yearly Terms : 1M consents/year',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.2',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P96EwB0P8XBvhyKq6bI6ajs',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713966722,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P96EwB0P8XBvhyKUhq78V7F',
          yearlyFlatFees: '{"eur":3000,"usd":3000,"cad":3000,"chf":3000,"gbp":3000}'
        },
        nickname: 'Flat fee for yearly Terms : 1M consents/year',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 300000,
        unit_amount_decimal: '300000'
      },
      {
        id: 'price_1NGjBcB0P8XBvhyKu0hRcGbf',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1686232056,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 10000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.114',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1NGjBbB0P8XBvhyKNSicgitN',
          meteredCriteria: 'termsConsents',
          yearlyFlatFee: '11400',
          yearlyMinUsage: '10000000'
        },
        nickname: 'yearly Terms for Enterprise (10M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 10000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.114',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1NGjBbB0P8XBvhyKNSicgitN',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1686232055,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1140000,
            unit_amount_decimal: '1140000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          meteredCriteria: 'termsConsents',
          tieredPriceId: 'price_1NGjBcB0P8XBvhyKu0hRcGbf'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (10M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1140000,
        unit_amount_decimal: '1140000'
      },
      {
        id: 'price_1NGjBZB0P8XBvhyKiUkSDJ4e',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1686232053,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 9000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1056',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1NGjBXB0P8XBvhyKKizyLLt1',
          meteredCriteria: 'termsConsents',
          yearlyFlatFee: '9500',
          yearlyMinUsage: '9000000'
        },
        nickname: 'yearly Terms for Enterprise (9M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 9000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.1056',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1NGjBXB0P8XBvhyKKizyLLt1',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1686232051,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 950000,
            unit_amount_decimal: '950000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          meteredCriteria: 'termsConsents',
          tieredPriceId: 'price_1NGjBZB0P8XBvhyKiUkSDJ4e'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (9M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 950000,
        unit_amount_decimal: '950000'
      },
      {
        id: 'price_1NGjBVB0P8XBvhyKEWqtQ2kh',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1686232049,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 8000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0988',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1NGjBTB0P8XBvhyKgijJfQcz',
          meteredCriteria: 'termsConsents',
          yearlyFlatFee: '7900',
          yearlyMinUsage: '8000000'
        },
        nickname: 'yearly Terms for Enterprise (8M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 8000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0988',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1NGjBTB0P8XBvhyKgijJfQcz',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1686232047,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 790000,
            unit_amount_decimal: '790000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          meteredCriteria: 'termsConsents',
          tieredPriceId: 'price_1NGjBVB0P8XBvhyKEWqtQ2kh'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (8M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 790000,
        unit_amount_decimal: '790000'
      },
      {
        id: 'price_1NGjBRB0P8XBvhyK8wMmhvWM',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1686232045,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 7000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0943',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1NGjBQB0P8XBvhyK0ve2dlKO',
          meteredCriteria: 'termsConsents',
          yearlyFlatFee: '6600',
          yearlyMinUsage: '7000000'
        },
        nickname: 'yearly Terms for Enterprise (7M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 7000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0943',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1NGjBQB0P8XBvhyK0ve2dlKO',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1686232044,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 660000,
            unit_amount_decimal: '660000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          meteredCriteria: 'termsConsents',
          tieredPriceId: 'price_1NGjBRB0P8XBvhyK8wMmhvWM'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (7M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 660000,
        unit_amount_decimal: '660000'
      },
      {
        id: 'price_1NGjBOB0P8XBvhyKaW4eskm2',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1686232042,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0917',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1NGjBLB0P8XBvhyKQPA4JuQY',
          meteredCriteria: 'termsConsents',
          yearlyFlatFee: '5500',
          yearlyMinUsage: '6000000'
        },
        nickname: 'yearly Terms for Enterprise (6M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 6000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0917',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1NGjBLB0P8XBvhyKQPA4JuQY',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1686232039,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 550000,
            unit_amount_decimal: '550000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          meteredCriteria: 'termsConsents',
          tieredPriceId: 'price_1NGjBOB0P8XBvhyKaW4eskm2'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (6M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 550000,
        unit_amount_decimal: '550000'
      },
      {
        id: 'price_1NGjBJB0P8XBvhyK9zpEQvib',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1686232037,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.092',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1NGjBIB0P8XBvhyKxZhF8VZP',
          meteredCriteria: 'termsConsents',
          yearlyFlatFee: '4600',
          yearlyMinUsage: '5000000'
        },
        nickname: 'yearly Terms for Enterprise (5M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 5000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.092',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1NGjBIB0P8XBvhyKxZhF8VZP',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1686232036,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 460000,
            unit_amount_decimal: '460000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          meteredCriteria: 'termsConsents',
          tieredPriceId: 'price_1NGjBJB0P8XBvhyK9zpEQvib'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (5M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 460000,
        unit_amount_decimal: '460000'
      },
      {
        id: 'price_1NGjBGB0P8XBvhyK0lIGrbzZ',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1686232034,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 4000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1025',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1NGjBEB0P8XBvhyKEzuJv05B',
          meteredCriteria: 'termsConsents',
          yearlyFlatFee: '4100',
          yearlyMinUsage: '4000000'
        },
        nickname: 'yearly Terms for Enterprise (4M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 4000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.1025',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1NGjBEB0P8XBvhyKEzuJv05B',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1686232032,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 410000,
            unit_amount_decimal: '410000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          meteredCriteria: 'termsConsents',
          tieredPriceId: 'price_1NGjBGB0P8XBvhyK0lIGrbzZ'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (4M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 410000,
        unit_amount_decimal: '410000'
      },
      {
        id: 'price_1NGjBCB0P8XBvhyKlFDkNce0',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1686232030,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 3000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1233',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1NGjBAB0P8XBvhyKTaGYqBa0',
          meteredCriteria: 'termsConsents',
          yearlyFlatFee: '3700',
          yearlyMinUsage: '3000000'
        },
        nickname: 'yearly Terms for Enterprise (3M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 3000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.1233',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1NGjBAB0P8XBvhyKTaGYqBa0',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1686232028,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 370000,
            unit_amount_decimal: '370000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          meteredCriteria: 'termsConsents',
          tieredPriceId: 'price_1NGjBCB0P8XBvhyKlFDkNce0'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (3M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 370000,
        unit_amount_decimal: '370000'
      },
      {
        id: 'price_1NGjB9B0P8XBvhyKOGy7AZo4',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1686232027,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.1675',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1NGjB7B0P8XBvhyKDg4xUecB',
          meteredCriteria: 'termsConsents',
          yearlyFlatFee: '3350',
          yearlyMinUsage: '2000000'
        },
        nickname: 'yearly Terms for Enterprise (2M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 2000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.1675',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1NGjB7B0P8XBvhyKDg4xUecB',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1686232025,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 335000,
            unit_amount_decimal: '335000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          meteredCriteria: 'termsConsents',
          tieredPriceId: 'price_1NGjB9B0P8XBvhyKOGy7AZo4'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (2M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 335000,
        unit_amount_decimal: '335000'
      },
      {
        id: 'price_1NGjB6B0P8XBvhyKXefOe9wd',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1686232024,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.3',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          flatFeePriceId: 'price_1NGjB3B0P8XBvhyKHxEkoBKi',
          meteredCriteria: 'termsConsents',
          yearlyFlatFee: '3000',
          yearlyMinUsage: '1000000'
        },
        nickname: 'yearly Terms for Enterprise (1M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.3',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1NGjB3B0P8XBvhyKHxEkoBKi',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1686232021,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'enterprise',
          meteredCriteria: 'termsConsents',
          tieredPriceId: 'price_1NGjB6B0P8XBvhyKXefOe9wd'
        },
        nickname: 'Flat fee for yearly Terms for Enterprise (1M consents/year)',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 300000,
        unit_amount_decimal: '300000'
      },
      {
        id: 'price_1NGjB1B0P8XBvhyKThUwppuB',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1686232019,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 19900,
            unit_amount_decimal: '19900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          customerType: 'agency'
        },
        nickname: 'monthly Terms for Agency',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 19900,
        unit_amount_decimal: '19900'
      },
      {
        id: 'price_1NGjB0B0P8XBvhyK4wSMgClv',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1686232018,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏'
        },
        nickname: 'yearly Terms Trial',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      },
      {
        id: 'price_1NGjAyB0P8XBvhyKaHFYkIJY',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1686232016,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏'
        },
        nickname: 'monthly Terms Trial',
        product: 'prod_terms',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      }
    ]
  },
  {
    id: 'prod_projectSelfServiceMetered',
    metadata: {
      WARNING: 'DO NOT EDIT METADATA !!! 🙏',
      meteredCriteria: 'pageViews',
      services: 'cookies,dpo',
      title_EN: 'Graduated self-service Project plan',
      title_FR: 'Offre Projet self-service progressive'
    },
    active: true,
    name: 'Graduated self-service Project',
    type: 'service',
    prices: [
      {
        id: 'price_1P6SHpB0P8XBvhyKWs5VpoZJ',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713336485,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Design customization • Standard statistics • Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Personnalisation graphique • TCF • Statistiques standards • Consent Wall',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1P6SHoB0P8XBvhyKogv7fvSx',
          graduatedFirstPaidTierFees: '{"eur":0.00039,"usd":0.00039,"cad":0.00039,"chf":0.00039,"gbp":0.00039}',
          label: 'Large',
          meteredCriteria: 'pageViews',
          multiCurrency: 'true',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsKpi: 'optInRate',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview',
          yearlyFlatFees: '{"eur":1393,"usd":1393,"cad":1393,"chf":1393,"gbp":1393}',
          yearlyMinUsage: '6000000',
          zd_priority: 'high'
        },
        nickname: 'Large yearly (6M page views/year)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 6000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.039',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P6SHoB0P8XBvhyKogv7fvSx',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713336484,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 139300,
            unit_amount_decimal: '139300'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 139300,
            unit_amount_decimal: '139300'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 139300,
            unit_amount_decimal: '139300'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 139300,
            unit_amount_decimal: '139300'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 139300,
            unit_amount_decimal: '139300'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P6SHpB0P8XBvhyKWs5VpoZJ',
          yearlyFlatFees: '{"eur":1393,"usd":1393,"cad":1393,"chf":1393,"gbp":1393}'
        },
        nickname: 'Flat fee for Large yearly (6M page views/year)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 139300,
        unit_amount_decimal: '139300'
      },
      {
        id: 'price_1P6SHmB0P8XBvhyKT4hW526K',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713336482,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Design customization • Standard statistics • Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Personnalisation graphique • TCF • Statistiques standards • Consent Wall',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1P6SHmB0P8XBvhyKlA7Fook2',
          graduatedFirstPaidTierFees: '{"eur":0.00039,"usd":0.00039,"cad":0.00039,"chf":0.00039,"gbp":0.00039}',
          label: 'Large',
          meteredCriteria: 'pageViews',
          monthlyFlatFees: '{"eur":129,"usd":129,"cad":129,"chf":129,"gbp":129}',
          monthlyMinUsage: '500000',
          multiCurrency: 'true',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsKpi: 'optInRate',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview',
          zd_priority: 'high'
        },
        nickname: 'Large monthly (500K page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 500000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.039',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P6SHmB0P8XBvhyKlA7Fook2',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713336482,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 12900,
            unit_amount_decimal: '12900'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 12900,
            unit_amount_decimal: '12900'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 12900,
            unit_amount_decimal: '12900'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 12900,
            unit_amount_decimal: '12900'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 12900,
            unit_amount_decimal: '12900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          monthlyFlatFees: '{"eur":129,"usd":129,"cad":129,"chf":129,"gbp":129}',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P6SHmB0P8XBvhyKT4hW526K'
        },
        nickname: 'Flat fee for Large monthly (500K page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 12900,
        unit_amount_decimal: '12900'
      },
      {
        id: 'price_1P6SHjB0P8XBvhyKZQeoySWD',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713336479,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1200000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1200000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1200000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1200000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1200000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Design customization • Basic statistics • Contextual Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Personnalisation graphique • TCF • Statistiques basiques • Consent Wall Contextuel',
          features: 'customization, contextualConsentWall',
          flatFeePriceId: 'price_1P6SHiB0P8XBvhyKFQ8Boexl',
          graduatedFirstPaidTierFees: '{"eur":0.00049,"usd":0.00049,"cad":0.00049,"chf":0.00049,"gbp":0.00049}',
          label: 'Medium',
          meteredCriteria: 'pageViews',
          multiCurrency: 'true',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          yearlyFlatFees: '{"eur":745,"usd":745,"cad":745,"chf":745,"gbp":745}',
          yearlyMinUsage: '1200000',
          zd_priority: 'normal'
        },
        nickname: 'Medium yearly (1.2M page views/year)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1200000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.049',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P6SHiB0P8XBvhyKFQ8Boexl',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713336478,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 74500,
            unit_amount_decimal: '74500'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 74500,
            unit_amount_decimal: '74500'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 74500,
            unit_amount_decimal: '74500'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 74500,
            unit_amount_decimal: '74500'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 74500,
            unit_amount_decimal: '74500'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P6SHjB0P8XBvhyKZQeoySWD',
          yearlyFlatFees: '{"eur":745,"usd":745,"cad":745,"chf":745,"gbp":745}'
        },
        nickname: 'Flat fee for Medium yearly (1.2M page views/year)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 74500,
        unit_amount_decimal: '74500'
      },
      {
        id: 'price_1P6SHgB0P8XBvhyK4XLKZBOr',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713336476,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Design customization • Basic statistics • Contextual Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Personnalisation graphique • TCF • Statistiques basiques • Consent Wall Contextuel',
          features: 'customization, contextualConsentWall',
          flatFeePriceId: 'price_1P6SHgB0P8XBvhyKvGt1hNIf',
          graduatedFirstPaidTierFees: '{"eur":0.00049,"usd":0.00049,"cad":0.00049,"chf":0.00049,"gbp":0.00049}',
          label: 'Medium',
          meteredCriteria: 'pageViews',
          monthlyFlatFees: '{"eur":69,"usd":69,"cad":69,"chf":69,"gbp":69}',
          monthlyMinUsage: '100000',
          multiCurrency: 'true',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          zd_priority: 'normal'
        },
        nickname: 'Medium monthly (100K page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 100000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.049',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P6SHgB0P8XBvhyKvGt1hNIf',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713336476,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 6900,
            unit_amount_decimal: '6900'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 6900,
            unit_amount_decimal: '6900'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 6900,
            unit_amount_decimal: '6900'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 6900,
            unit_amount_decimal: '6900'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 6900,
            unit_amount_decimal: '6900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          monthlyFlatFees: '{"eur":69,"usd":69,"cad":69,"chf":69,"gbp":69}',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P6SHgB0P8XBvhyK4XLKZBOr'
        },
        nickname: 'Flat fee for Medium monthly (100K page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 6900,
        unit_amount_decimal: '6900'
      },
      {
        id: 'price_1P6SHdB0P8XBvhyKgr3hnjyZ',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713336473,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Design customization • Basic statistics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Personnalisation graphique • TCF • Statistiques basiques',
          features: 'customization',
          flatFeePriceId: 'price_1P6SHbB0P8XBvhyK9Iq4ldHj',
          graduatedFirstPaidTierFees: '{"eur":0.0018,"usd":0.0018,"cad":0.0018,"chf":0.0018,"gbp":0.0018}',
          label: 'Small',
          meteredCriteria: 'pageViews',
          multiCurrency: 'true',
          services: 'cookies,dpo,tcf',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          yearlyFlatFees: '{"eur":313,"usd":313,"cad":313,"chf":313,"gbp":313}',
          yearlyMinUsage: '60000',
          zd_priority: 'low'
        },
        nickname: 'Small yearly (60K page views/year)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 60000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.18',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P6SHbB0P8XBvhyK9Iq4ldHj',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713336471,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 31300,
            unit_amount_decimal: '31300'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 31300,
            unit_amount_decimal: '31300'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 31300,
            unit_amount_decimal: '31300'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 31300,
            unit_amount_decimal: '31300'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 31300,
            unit_amount_decimal: '31300'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P6SHdB0P8XBvhyKgr3hnjyZ',
          yearlyFlatFees: '{"eur":313,"usd":313,"cad":313,"chf":313,"gbp":313}'
        },
        nickname: 'Flat fee for Small yearly (60K page views/year)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 31300,
        unit_amount_decimal: '31300'
      },
      {
        id: 'price_1P6SHbB0P8XBvhyKsmHj36j4',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713336471,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Design customization • Basic statistics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Personnalisation graphique • TCF • Statistiques basiques',
          features: 'customization',
          flatFeePriceId: 'price_1P6SH8B0P8XBvhyKDwMd7o27',
          graduatedFirstPaidTierFees: '{"eur":0.0018,"usd":0.0018,"cad":0.0018,"chf":0.0018,"gbp":0.0018}',
          label: 'Small',
          meteredCriteria: 'pageViews',
          monthlyFlatFees: '{"eur":29,"usd":29,"cad":29,"chf":29,"gbp":29}',
          monthlyMinUsage: '5000',
          multiCurrency: 'true',
          services: 'cookies,dpo,tcf',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          zd_priority: 'low'
        },
        nickname: 'Small monthly (5K page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 5000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.18',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1P6SH8B0P8XBvhyKDwMd7o27',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1713336442,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2900,
            unit_amount_decimal: '2900'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2900,
            unit_amount_decimal: '2900'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2900,
            unit_amount_decimal: '2900'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2900,
            unit_amount_decimal: '2900'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2900,
            unit_amount_decimal: '2900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          monthlyFlatFees: '{"eur":29,"usd":29,"cad":29,"chf":29,"gbp":29}',
          multiCurrency: 'true',
          tieredPriceId: 'price_1P6SHbB0P8XBvhyKsmHj36j4'
        },
        nickname: 'Flat fee for Small monthly (5K page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2900,
        unit_amount_decimal: '2900'
      },
      {
        id: 'price_1P6SGFB0P8XBvhyK7l3cVmUV',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1713336387,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • Marketing consents • Email support • Design customization • Standard statistics • Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Consentements marketing • Support tel/mail • Personnalisation graphique • Statistiques standards • Consent Wall',
          features: 'customization, consentWall',
          graduatedFirstPaidTierFees: '{"eur":0,"usd":0,"cad":0,"chf":0,"gbp":0}',
          label: 'Free Trial',
          meteredCriteria: 'newVisitors',
          monthlyFlatFee: '0',
          monthlyMinUsage: '200',
          multiCurrency: 'true',
          services: 'cookies,processings',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsKpi: 'optInRate',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview',
          zd_priority: 'low'
        },
        nickname: 'Free Trial monthly (200 new visitors/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 200
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OegC5B0P8XBvhyK1owebZna',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1706716761,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF • Standard statistics • Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF • Statistiques standards • Consent Wall',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1OegC3B0P8XBvhyK1ugVhGOw',
          label: 'Large',
          monthlyFlatFee: '129',
          monthlyMinUsage: '500000',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsKpi: 'optInRate',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview',
          yearlyFlatFee: '1392',
          yearlyMinUsage: '6000000',
          zd_priority: 'high'
        },
        nickname: 'Large yearly (500k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 6000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.039',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OegC3B0P8XBvhyK1ugVhGOw',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1706716759,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 139200,
            unit_amount_decimal: '139200'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1OegC5B0P8XBvhyK1owebZna'
        },
        nickname: 'Flat fee for Large yearly (500k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 139200,
        unit_amount_decimal: '139200'
      },
      {
        id: 'price_1OegC1B0P8XBvhyKIIcOCZY4',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1706716757,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.039',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF • Standard statistics • Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF • Statistiques standards • Consent Wall',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1OegC1B0P8XBvhyKSc9tPLy8',
          label: 'Large',
          monthlyFlatFee: '129',
          monthlyMinUsage: '500000',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsKpi: 'optInRate',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview',
          zd_priority: 'high'
        },
        nickname: 'Large monthly (500k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 500000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.039',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OegC1B0P8XBvhyKSc9tPLy8',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1706716757,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 12900,
            unit_amount_decimal: '12900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1OegC1B0P8XBvhyKIIcOCZY4'
        },
        nickname: 'Flat fee for Large monthly (500k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 12900,
        unit_amount_decimal: '12900'
      },
      {
        id: 'price_1O1kjTB0P8XBvhyKQTt5MTQe',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1697439415,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0198',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • Standard statistics • Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • Statistiques standards • Consent Wall',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1O1kjSB0P8XBvhyKAVzw0cYx',
          label: 'Large',
          monthlyFlatFee: '129',
          monthlyMinUsage: '500000',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsKpi: 'optInRate',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview',
          yearlyFlatFee: '1392',
          yearlyMinUsage: '6000000',
          zd_priority: 'high'
        },
        nickname: 'Large yearly (500k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 6000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0198',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1O1kjSB0P8XBvhyKAVzw0cYx',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1697439414,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 139200,
            unit_amount_decimal: '139200'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1O1kjTB0P8XBvhyKQTt5MTQe'
        },
        nickname: 'Flat fee for Large yearly (500k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 139200,
        unit_amount_decimal: '139200'
      },
      {
        id: 'price_1O1kjRB0P8XBvhyKEmDDT8dm',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1697439413,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0198',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • Standard statistics • Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • Statistiques standards • Consent Wall',
          features: 'customization, consentWall',
          flatFeePriceId: 'price_1O1kjQB0P8XBvhyKyr2AruQX',
          label: 'Large',
          monthlyFlatFee: '129',
          monthlyMinUsage: '500000',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsKpi: 'optInRate',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview',
          zd_priority: 'high'
        },
        nickname: 'Large monthly (500k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 500000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0198',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1O1kjQB0P8XBvhyKyr2AruQX',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1697439412,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 12900,
            unit_amount_decimal: '12900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1O1kjRB0P8XBvhyKEmDDT8dm'
        },
        nickname: 'Flat fee for Large monthly (500k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 12900,
        unit_amount_decimal: '12900'
      },
      {
        id: 'price_1O1kjNB0P8XBvhyKtV5occEs',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1697439409,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1200000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF • Basic statistics • Contextual Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF • Statistiques basiques • Consent Wall Contextuel',
          features: 'customization, contextualConsentWall',
          flatFeePriceId: 'price_1O1kjMB0P8XBvhyK6LOIlfxF',
          label: 'Medium',
          monthlyFlatFee: '69',
          monthlyMinUsage: '100000',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          yearlyFlatFee: '744',
          yearlyMinUsage: '1200000',
          zd_priority: 'normal'
        },
        nickname: 'Medium yearly (100k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1200000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.049',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1O1kjMB0P8XBvhyK6LOIlfxF',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1697439408,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 74400,
            unit_amount_decimal: '74400'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1O1kjNB0P8XBvhyKtV5occEs'
        },
        nickname: 'Flat fee for Medium yearly (100k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 74400,
        unit_amount_decimal: '74400'
      },
      {
        id: 'price_1O1kjEB0P8XBvhyKyFMtuoIA',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1697439400,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF • Basic statistics • Contextual Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF • Statistiques basiques • Consent Wall Contextuel',
          features: 'customization, contextualConsentWall',
          flatFeePriceId: 'price_1O1kjEB0P8XBvhyKxyiA6oeM',
          label: 'Medium',
          monthlyFlatFee: '69',
          monthlyMinUsage: '100000',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          zd_priority: 'normal'
        },
        nickname: 'Medium monthly (100k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 100000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.049',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1O1kjEB0P8XBvhyKxyiA6oeM',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1697439400,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 6900,
            unit_amount_decimal: '6900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1O1kjEB0P8XBvhyKyFMtuoIA'
        },
        nickname: 'Flat fee for Medium monthly (100k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 6900,
        unit_amount_decimal: '6900'
      },
      {
        id: 'price_1O1kj3B0P8XBvhyKq7ZHTGHR',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1697439389,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF • Basic statistics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF • Statistiques basiques',
          features: 'customization',
          flatFeePriceId: 'price_1O1kj3B0P8XBvhyKDQ0nXKlV',
          label: 'Small',
          monthlyFlatFee: '29',
          monthlyMinUsage: '5000',
          services: 'cookies,dpo,tcf',
          yearlyFlatFee: '312',
          yearlyMinUsage: '60000',
          zd_priority: 'low'
        },
        nickname: 'Small yearly (5k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 60000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.18',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1O1kj3B0P8XBvhyKDQ0nXKlV',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1697439389,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 31200,
            unit_amount_decimal: '31200'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1O1kj3B0P8XBvhyKq7ZHTGHR'
        },
        nickname: 'Flat fee for Small yearly (5k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 31200,
        unit_amount_decimal: '31200'
      },
      {
        id: 'price_1O1kiZB0P8XBvhyKy3kTbD95',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1697439359,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF • Basic statistics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF • Statistiques basiques',
          features: 'customization',
          flatFeePriceId: 'price_1O1kiZB0P8XBvhyKp1aP7NJV',
          label: 'Small',
          monthlyFlatFee: '29',
          monthlyMinUsage: '5000',
          services: 'cookies,dpo,tcf',
          zd_priority: 'low'
        },
        nickname: 'Small monthly (5k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 5000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.18',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1O1kiZB0P8XBvhyKp1aP7NJV',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1697439359,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2900,
            unit_amount_decimal: '2900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1O1kiZB0P8XBvhyKy3kTbD95'
        },
        nickname: 'Flat fee for Small monthly (5k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 2900,
        unit_amount_decimal: '2900'
      },
      {
        id: 'price_1NdSOaB0P8XBvhyKyWnONf8Q',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1691649056,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500,
            unit_amount_decimal: '1500'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_projectSelfServiceMetered',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 1500,
        unit_amount_decimal: '1500'
      },
      {
        id: 'price_1MiZxVB0P8XBvhyK3Lopt5i9',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1678093433,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 16200,
            unit_amount_decimal: '16200'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          createdFromPrice: 'price_1M9lr8B0P8XBvhyKzZ4qypym',
          tieredPriceId: 'price_1LrHPGB0P8XBvhyKzOSVCzcY'
        },
        nickname: 'Flat fee for Small yearly (5k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 16200,
        unit_amount_decimal: '16200'
      },
      {
        id: 'price_1MiZwSB0P8XBvhyKlFBEyy6M',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1678093368,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500,
            unit_amount_decimal: '1500'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          createdFromPrice: 'price_1LrHHYB0P8XBvhyKJ3QyYF8D',
          tieredPriceId: 'price_1LrHLAB0P8XBvhyKEgM1u0bD'
        },
        nickname: 'Flat fee for Small monthly (5k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500,
        unit_amount_decimal: '1500'
      },
      {
        id: 'price_1M9lr8B0P8XBvhyKlXkM8oAG',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1669797686,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 106920,
            unit_amount_decimal: '106920'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          createdFromPrice: 'price_1LPhuvB0P8XBvhyKNgZoVtAm',
          tieredPriceId: 'price_1LPhuwB0P8XBvhyKnRvTll4Y'
        },
        nickname: 'Flat fee for Large yearly (500k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 106920,
        unit_amount_decimal: '106920'
      },
      {
        id: 'price_1M9lr8B0P8XBvhyKXjNPPylJ',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1669797686,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 52920,
            unit_amount_decimal: '52920'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          createdFromPrice: 'price_1LPhupB0P8XBvhyKhSQ5CSMI',
          tieredPriceId: 'price_1LPhurB0P8XBvhyK3LOg72Kt'
        },
        nickname: 'Flat fee for Medium yearly (100k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 52920,
        unit_amount_decimal: '52920'
      },
      {
        id: 'price_1M9lr8B0P8XBvhyKzZ4qypym',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1669797686,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9720,
            unit_amount_decimal: '9720'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          createdFromPrice: 'price_1LrHJHB0P8XBvhyKeynhie3M',
          tieredPriceId: 'price_1LrHPGB0P8XBvhyKzOSVCzcY'
        },
        nickname: 'Flat fee for Small yearly (5k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9720,
        unit_amount_decimal: '9720'
      },
      {
        id: 'price_1LrHPGB0P8XBvhyKzOSVCzcY',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1665390734,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 60000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF',
          features: 'customization',
          flatFeePriceId: 'price_1MiZxVB0P8XBvhyK3Lopt5i9',
          label: 'Small',
          monthlyFlatFee: '15',
          monthlyMinUsage: '5000',
          services: 'cookies,dpo,tcf',
          zd_priority: 'low'
        },
        nickname: 'Small yearly (5k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 60000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.18',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LrHLAB0P8XBvhyKEgM1u0bD',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1665390480,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 5000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.18',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF • Basic statistics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF • Statistiques basiques',
          features: 'customization',
          flatFeePriceId: 'price_1MiZwSB0P8XBvhyKlFBEyy6M',
          label: 'Small',
          monthlyFlatFee: '15',
          monthlyMinUsage: '5000',
          services: 'cookies,dpo,tcf',
          zd_priority: 'low'
        },
        nickname: 'Small monthly (5k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 5000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.18',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LrHJHB0P8XBvhyKeynhie3M',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1665390363,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 10800,
            unit_amount_decimal: '10800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1LrHPGB0P8XBvhyKzOSVCzcY'
        },
        nickname: 'Flat fee for Small yearly (5k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 10800,
        unit_amount_decimal: '10800'
      },
      {
        id: 'price_1LrHHYB0P8XBvhyKJ3QyYF8D',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1665390256,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 900,
            unit_amount_decimal: '900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1LrHLAB0P8XBvhyKEgM1u0bD'
        },
        nickname: 'Flat fee for Small monthly (5k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 900,
        unit_amount_decimal: '900'
      },
      {
        id: 'price_1LiaZEB0P8XBvhyKj6ExrLTF',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1663319436,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 10800,
            unit_amount_decimal: '10800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_projectSelfServiceMetered',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 10800,
        unit_amount_decimal: '10800'
      },
      {
        id: 'price_1LPhuwB0P8XBvhyKnRvTll4Y',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658819458,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 6000000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0198',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Graphic customization • Contextual Consent Wall • TCF • Basic Analytics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • Consent Wall Contextuel • TCF • Stats de base',
          features: 'customization,consentWall',
          flatFeePriceId: 'price_1M9lr8B0P8XBvhyKlXkM8oAG',
          label: 'Large',
          monthlyFlatFee: '99',
          monthlyMinUsage: '500000',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsKpi: 'optInRate',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview',
          zd_priority: 'high'
        },
        nickname: 'Large yearly (500k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 6000000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0198',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhuvB0P8XBvhyKNgZoVtAm',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658819457,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 118800,
            unit_amount_decimal: '118800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1LPhuwB0P8XBvhyKnRvTll4Y'
        },
        nickname: 'Flat fee for Large yearly (500k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 118800,
        unit_amount_decimal: '118800'
      },
      {
        id: 'price_1LPhuuB0P8XBvhyKp564AxX7',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658819456,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 500000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.0198',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF • Standard statistics • Consent Wall',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF • Statistiques standards • Consent Wall',
          features: 'customization,consentWall',
          flatFeePriceId: 'price_1LPhutB0P8XBvhyKAePhorbj',
          label: 'Large',
          monthlyFlatFee: '99',
          monthlyMinUsage: '500000',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsKpi: 'optInRate',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview',
          zd_priority: 'high'
        },
        nickname: 'Large monthly (500k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 500000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.0198',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhutB0P8XBvhyKAePhorbj',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658819455,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9900,
            unit_amount_decimal: '9900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1LPhuuB0P8XBvhyKp564AxX7'
        },
        nickname: 'Flat fee for Large monthly (500k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9900,
        unit_amount_decimal: '9900'
      },
      {
        id: 'price_1LPhurB0P8XBvhyK3LOg72Kt',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658819453,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 1200000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF',
          features: 'customization',
          flatFeePriceId: 'price_1M9lr8B0P8XBvhyKXjNPPylJ',
          label: 'Medium',
          monthlyFlatFee: '49',
          monthlyMinUsage: '100000',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          zd_priority: 'normal'
        },
        nickname: 'Medium yearly (100k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 1200000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.049',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhupB0P8XBvhyKhSQ5CSMI',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658819451,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 58800,
            unit_amount_decimal: '58800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1LPhurB0P8XBvhyK3LOg72Kt'
        },
        nickname: 'Flat fee for Medium yearly (100k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 58800,
        unit_amount_decimal: '58800'
      },
      {
        id: 'price_1LPhunB0P8XBvhyKEyEDDkUe',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658819449,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 100000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.049',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF • Basic statistics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF • Statistiques basiques',
          features: 'customization',
          flatFeePriceId: 'price_1LPhunB0P8XBvhyKYSGIBv8x',
          label: 'Medium',
          monthlyFlatFee: '49',
          monthlyMinUsage: '100000',
          services: 'cookies,dpo,tcf,analyticsConnectors',
          zd_priority: 'normal'
        },
        nickname: 'Medium monthly (100k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 100000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.049',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhunB0P8XBvhyKYSGIBv8x',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658819449,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4900,
            unit_amount_decimal: '4900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1LPhunB0P8XBvhyKEyEDDkUe'
        },
        nickname: 'Flat fee for Medium monthly (100k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4900,
        unit_amount_decimal: '4900'
      },
      {
        id: 'price_1LPhulB0P8XBvhyKZJ1GluzD',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658819447,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 600000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.018',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF',
          features: 'customization',
          flatFeePriceId: 'price_1LPhuiB0P8XBvhyKCFYs4sl1',
          label: 'Small',
          monthlyFlatFee: '9',
          monthlyMinUsage: '50000',
          services: 'cookies,dpo,tcf',
          zd_priority: 'low'
        },
        nickname: 'Small yearly (50k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 600000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.018',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhuiB0P8XBvhyKCFYs4sl1',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658819444,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 10800,
            unit_amount_decimal: '10800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1LPhulB0P8XBvhyKZJ1GluzD'
        },
        nickname: 'Flat fee for Small yearly (50k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 10800,
        unit_amount_decimal: '10800'
      },
      {
        id: 'price_1LPhuhB0P8XBvhyKG8d5wqb6',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658819443,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 50000
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: null,
                unit_amount_decimal: '0.018',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Design customization • TCF',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Personnalisation graphique • TCF',
          features: 'customization',
          flatFeePriceId: 'price_1LPhuhB0P8XBvhyKnpeFJsg3',
          label: 'Small',
          monthlyFlatFee: '9',
          monthlyMinUsage: '50000',
          services: 'cookies,dpo,tcf',
          zd_priority: 'low'
        },
        nickname: 'Small monthly (50k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 50000
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: null,
            unit_amount_decimal: '0.018',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhuhB0P8XBvhyKnpeFJsg3',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1658819443,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 900,
            unit_amount_decimal: '900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1LPhuhB0P8XBvhyKG8d5wqb6'
        },
        nickname: 'Flat fee for Small monthly (50k page views/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 900,
        unit_amount_decimal: '900'
      },
      {
        id: 'price_1LPhufB0P8XBvhyKJK09lGjR',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658819441,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 2400
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Graphic customization  • Storage of consents • Marketing consents • Contractual consents • Contextual Consent Wall • Basic Analytics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Personnalisation graphique • Consentements marketing • Consentement contractuel • Consent Wall Contextuel • Stats de base',
          features: 'customization,consentWall',
          label: 'Free Trial',
          meteredCriteria: 'newVisitors',
          monthlyFlatFee: '0',
          monthlyMinUsage: '200',
          services: 'cookies,processings,contracts,dpo,portability',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview, optInRate',
          zd_priority: 'low'
        },
        nickname: 'Free Trial yearly (200 new visitors/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 2400
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhueB0P8XBvhyKJzdbdGQ5',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1658819440,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 200
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Multilingual • Storage of consents • email support • Graphic customization  • Storage of consents • Marketing consents • Contractual consents • Consent Wall • Standard statistics',
          description_FR: 'Cookies illimités • Multilingues • Stockage des consentements • Support tel/mail • Personnalisation graphique • Consentements marketing • Consentement contractuel • Consent Wall • Statistiques standards',
          features: 'customization,consentWall',
          label: 'Free Trial',
          meteredCriteria: 'newVisitors',
          monthlyFlatFee: '0',
          monthlyMinUsage: '200',
          services: 'cookies,processings,contracts,dpo,portability',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview, optInRate',
          zd_priority: 'low'
        },
        nickname: 'Free Trial monthly (200 new visitors/month)',
        product: 'prod_projectSelfServiceMetered',
        recurring: {
          aggregate_usage: 'sum',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 200
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      }
    ]
  },
  {
    id: 'prod_PvxXxBPWWMKbMx',
    metadata: {},
    active: false,
    name: 'Graduated Agency',
    type: 'service',
    prices: [
      {
        id: 'price_1P65ciB0P8XBvhyKf5tb2rbK',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1713249368,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9900,
            unit_amount_decimal: '9900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_PvxXxBPWWMKbMx',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 9900,
        unit_amount_decimal: '9900'
      }
    ]
  },
  {
    id: 'prod_agencyMetered',
    metadata: {
      WARNING: 'DO NOT EDIT METADATA !!! 🙏',
      backoffice_agency: 'metered_standard',
      features: 'customization',
      meteredCriteria: 'publishedProjects',
      services: 'cookies,dpo,automatorScanner,automatorStylesheet,automatorProvisioning,automatorEmailing,tcf,analyticsConnectors',
      statsAllowedAggregateBy: 'day,week',
      statsAreFiltersAllowed: 'false',
      statsPeriod: 'lastWeek',
      statsWidgets: 'dailyOverview',
      title_EN: 'Graduated Agency plan',
      title_FR: 'Offre Agence progressive',
      zd_priority: 'high'
    },
    active: true,
    name: 'Graduated Agency',
    type: 'service',
    prices: [
      {
        id: 'price_1OvxRmB0P8XBvhyK25NPY1SL',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1710834658,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 100000,
            unit_amount_decimal: '100000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 100000,
            unit_amount_decimal: '100000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 100000,
            unit_amount_decimal: '100000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 100000,
            unit_amount_decimal: '100000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 120000,
            unit_amount_decimal: '120000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Cookies scanner • Email support • Payment by bank transfer • Complete Analytics • Consent Wall • TCF • Dedicated CS • API Provisioning • AB testing • Max 750k page views/project',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Audit des traceurs • Support tel/mail • Paiement par virement • Stats complètes • Consent Wall • TCF • CS Dédié • Accès API • AB testing • Max 750k pages vues/projet',
          features: 'customization, consentWall',
          label: 'Agency unlimited projects',
          label_EN: 'Agency unlimited projects',
          label_FR: 'Agence projets illimités',
          monthlyFlatFees: '{"eur":1000,"usd":1200,"cad":1000,"chf":1000,"gbp":1000}',
          multiCurrency: 'true',
          pageViewsLimit: '750000',
          partOfAutoUpscale: 'false',
          services: 'cookies,dpo,processings,automatorScanner,automatorStylesheet,automatorProvisioning,automatorEmailing',
          statsAllowedAggregateBy: 'day,week,month, year',
          statsAreFiltersAllowed: 'true',
          statsKpi: 'pageview,visitor,newVisitor,consentRate,interactionRate,optinRate,hits,quickBounceRate,consentRateMobile,consentRateDesktop,interactionRateMobile,interactionRateDesktop',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview,dailyVisitsOverview,optInRate,consentDistribution,visitsByHosts,consentDistributionByDevice,optInRateByDevice,devicesDistribution,bounceRateByDuration',
          zd_priority: 'high'
        },
        nickname: 'Agencies 2024 monthly (unlimited published projects)',
        product: 'prod_agencyMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 100000,
        unit_amount_decimal: '100000'
      },
      {
        id: 'price_1OJVonB0P8XBvhyKrXH4wGgM',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1701671989,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 3000,
                unit_amount_decimal: '3000',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 2000,
                unit_amount_decimal: '2000',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 2000,
                unit_amount_decimal: '2000',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 2000,
                unit_amount_decimal: '2000',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 25
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 2500,
                unit_amount_decimal: '2500',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Cookies scanner • Email support • Payment by bank transfer • Complete Analytics • Consent Wall • TCF • Dedicated CS • API Provisioning • AB testing • Max 750k page views/project',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Audit des traceurs • Support tel/mail • Paiement par virement • Stats complètes • Consent Wall • TCF • CS Dédié • Accès API • AB testing • Max 750k pages vues/projet',
          features: 'customization, consentWall, cookiesConfigTranslation',
          flatFeePriceId: 'price_1OJVonB0P8XBvhyKU2607wA5',
          graduatedFirstPaidTierFees: '{"eur":20,"usd":25,"cad":30,"chf":20,"gbp":20}',
          label: 'Agency 25 projects',
          label_EN: 'Agency 25 projects',
          label_FR: 'Agence 25 projets',
          meteredCriteria: 'publishedProjects',
          monthlyFlatFees: '{"eur":600,"usd":700,"cad":900,"chf":600,"gbp":600}',
          monthlyMinUsage: '25',
          multiCurrency: 'true',
          pageViewsLimit: '750000',
          partOfAutoUpscale: 'false',
          services: 'cookies,dpo,processings,automatorScanner,automatorStylesheet,automatorProvisioning,automatorEmailing',
          statsAllowedAggregateBy: 'day,week,month, year',
          statsAreFiltersAllowed: 'true',
          statsKpi: 'pageview,visitor,newVisitor,consentRate,interactionRate,optInRate,hits,quickBounceRate,consentRateMobile,consentRateDesktop,interactionRateMobile,interactionRateDesktop',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview,dailyVisitsOverview,consentDistribution,visitsByHosts,consentDistributionByDevice,optInRateByDevice,devicesDistribution,bounceRateByDuration',
          zd_priority: 'high'
        },
        nickname: 'Agencies 2023 monthly (25 published projects/month)',
        product: 'prod_agencyMetered',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 25
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 2000,
            unit_amount_decimal: '2000',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1OJVonB0P8XBvhyKU2607wA5',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1701671989,
        currency: 'eur',
        currency_options: {
          cad: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 90000,
            unit_amount_decimal: '90000'
          },
          chf: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 60000,
            unit_amount_decimal: '60000'
          },
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 60000,
            unit_amount_decimal: '60000'
          },
          gbp: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 60000,
            unit_amount_decimal: '60000'
          },
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 70000,
            unit_amount_decimal: '70000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          monthlyFlatFees: '{"eur":600,"usd":700,"cad":900,"chf":600,"gbp":600}',
          multiCurrency: 'true',
          tieredPriceId: 'price_1OJVonB0P8XBvhyKrXH4wGgM'
        },
        nickname: 'Flat fee for Agencies 2023 monthly (25 published projects/month)',
        product: 'prod_agencyMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 60000,
        unit_amount_decimal: '60000'
      },
      {
        id: 'price_1MrddGB0P8XBvhyKTGbjk3Zh',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1680252506,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 10
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 600,
                unit_amount_decimal: '600',
                up_to: 20
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 450,
                unit_amount_decimal: '450',
                up_to: 50
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 250,
                unit_amount_decimal: '250',
                up_to: 100
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 200,
                unit_amount_decimal: '200',
                up_to: 250
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 100,
                unit_amount_decimal: '100',
                up_to: 500
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 20,
                unit_amount_decimal: '20',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Graphic customization • Multilingual • Storage of consents • email support • Payment by bank transfer • Basic Analytics • TCF',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Stats de base • TCF',
          flatFeePriceId: 'price_1MoOfcB0P8XBvhyK65X2NNCL',
          label: 'Small',
          monthlyFlatFee: '99',
          monthlyMinUsage: '10',
          partOfAutoUpscale: 'true'
        },
        nickname: 'Small monthly (10 published projects/month)',
        product: 'prod_agencyMetered',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 10
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 600,
            unit_amount_decimal: '600',
            up_to: 20
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 450,
            unit_amount_decimal: '450',
            up_to: 50
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 250,
            unit_amount_decimal: '250',
            up_to: 100
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 200,
            unit_amount_decimal: '200',
            up_to: 250
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 100,
            unit_amount_decimal: '100',
            up_to: 500
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 20,
            unit_amount_decimal: '20',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1MoOmzB0P8XBvhyKpysaPQHn',
        object: 'price',
        active: false,
        billing_scheme: 'tiered',
        created: 1679480465,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 10
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 600,
                unit_amount_decimal: '600',
                up_to: 20
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 450,
                unit_amount_decimal: '450',
                up_to: 50
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 250,
                unit_amount_decimal: '250',
                up_to: 100
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 200,
                unit_amount_decimal: '200',
                up_to: 250
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 100,
                unit_amount_decimal: '100',
                up_to: 500
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 20,
                unit_amount_decimal: '20',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Graphic customization • Multilingual • Storage of consents • email support • Payment by bank transfer • Basic Analytics',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Support tel/mail • Paiement par virement • Stats de base',
          flatFeePriceId: 'price_1MoOfcB0P8XBvhyK65X2NNCL',
          label: 'Small',
          monthlyFlatFee: '99',
          monthlyMinUsage: '10'
        },
        nickname: 'Small monthly (10 published projects/month)',
        product: 'prod_agencyMetered',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 10
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 600,
            unit_amount_decimal: '600',
            up_to: 20
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 450,
            unit_amount_decimal: '450',
            up_to: 50
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 250,
            unit_amount_decimal: '250',
            up_to: 100
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 200,
            unit_amount_decimal: '200',
            up_to: 250
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 100,
            unit_amount_decimal: '100',
            up_to: 500
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 20,
            unit_amount_decimal: '20',
            up_to: null
          }
        ],
        tiers_mode: 'volume',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1MoOfcB0P8XBvhyK65X2NNCL',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1679480008,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9900,
            unit_amount_decimal: '9900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1MrddGB0P8XBvhyKTGbjk3Zh'
        },
        nickname: 'Flat fee for Small monthly (10 published projects/month)',
        product: 'prod_agencyMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9900,
        unit_amount_decimal: '9900'
      },
      {
        id: 'price_1LPhv7B0P8XBvhyKF22fIvpw',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819469,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 56
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 250,
                unit_amount_decimal: '250',
                up_to: 100
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 200,
                unit_amount_decimal: '200',
                up_to: 250
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 100,
                unit_amount_decimal: '100',
                up_to: 500
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 20,
                unit_amount_decimal: '20',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • email support • Payment by bank transfer • Complete Analytics • Contextual Consent Wall • TCF • Mobile SDK',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail • Paiement par virement • Stats complètes • Consent Wall Contextuel • TCF • SDK mobile',
          features: 'consentWall, mobileSDK, cookiesConfigTranslation',
          flatFeePriceId: 'price_1LPhv7B0P8XBvhyKkPD1IDAD',
          label: 'Premium',
          monthlyFlatFee: '300',
          monthlyMinUsage: '56',
          partOfAutoUpscale: 'true',
          services: 'cookies,dpo,processings,contracts',
          statsAllowedAggregateBy: 'day,week,month, year',
          statsAreFiltersAllowed: 'true',
          statsKpi: 'pageview,visitor,newVisitor,consentRate,interactionRate,optInRate,hits,quickBounceRate,consentRateMobile,consentRateDesktop,interactionRateMobile,interactionRateDesktop',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview,dailyVisitsOverview,consentDistribution,visitsByHosts,consentDistributionByDevice,optInRateByDevice,devicesDistribution,bounceRateByDuration'
        },
        nickname: 'Premium monthly (56 published projects/month)',
        product: 'prod_agencyMetered',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 56
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 250,
            unit_amount_decimal: '250',
            up_to: 100
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 200,
            unit_amount_decimal: '200',
            up_to: 250
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 100,
            unit_amount_decimal: '100',
            up_to: 500
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 20,
            unit_amount_decimal: '20',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhv7B0P8XBvhyKkPD1IDAD',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819469,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 30000,
            unit_amount_decimal: '30000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1LPhv7B0P8XBvhyKF22fIvpw'
        },
        nickname: 'Flat fee for Premium monthly (56 published projects/month)',
        product: 'prod_agencyMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 30000,
        unit_amount_decimal: '30000'
      },
      {
        id: 'price_1LPhv4B0P8XBvhyKshAR6na5',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819466,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 35
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 450,
                unit_amount_decimal: '450',
                up_to: 50
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 250,
                unit_amount_decimal: '250',
                up_to: 100
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 200,
                unit_amount_decimal: '200',
                up_to: 250
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 100,
                unit_amount_decimal: '100',
                up_to: 500
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 20,
                unit_amount_decimal: '20',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • email support • Payment by bank transfer • Extended Analytics • Contextual Consent Wall • TCF',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail • Paiement par virement • Stats étendues • Consent Wall Contextuel • TCF',
          features: 'consentWall',
          flatFeePriceId: 'price_1LPhv3B0P8XBvhyK0gxl4yzS',
          label: 'Pro',
          monthlyFlatFee: '220',
          monthlyMinUsage: '35',
          partOfAutoUpscale: 'true',
          services: 'cookies,dpo,processings,contracts',
          statsAllowedAggregateBy: 'day,week,month',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastMonth',
          statsWidgets: 'dailyOverview,dailyVisitsOverview,optInRate,consentDistribution,visitsByHosts,bounceRateByDuration'
        },
        nickname: 'Pro monthly (35 published projects/month)',
        product: 'prod_agencyMetered',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 35
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 450,
            unit_amount_decimal: '450',
            up_to: 50
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 250,
            unit_amount_decimal: '250',
            up_to: 100
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 200,
            unit_amount_decimal: '200',
            up_to: 250
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 100,
            unit_amount_decimal: '100',
            up_to: 500
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 20,
            unit_amount_decimal: '20',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhv3B0P8XBvhyK0gxl4yzS',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819465,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 22000,
            unit_amount_decimal: '22000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1LPhv4B0P8XBvhyKshAR6na5'
        },
        nickname: 'Flat fee for Pro monthly (35 published projects/month)',
        product: 'prod_agencyMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 22000,
        unit_amount_decimal: '22000'
      },
      {
        id: 'price_1LPhv0B0P8XBvhyKzJ8gRANW',
        object: 'price',
        active: true,
        billing_scheme: 'tiered',
        created: 1658819462,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            tiers: [
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 0,
                unit_amount_decimal: '0',
                up_to: 20
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 450,
                unit_amount_decimal: '450',
                up_to: 50
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 250,
                unit_amount_decimal: '250',
                up_to: 100
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 200,
                unit_amount_decimal: '200',
                up_to: 250
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 100,
                unit_amount_decimal: '100',
                up_to: 500
              },
              {
                flat_amount: null,
                flat_amount_decimal: null,
                unit_amount: 20,
                unit_amount_decimal: '20',
                up_to: null
              }
            ],
            unit_amount: null,
            unit_amount_decimal: null
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • email support • Payment by bank transfer • Basic Analytics • TCF',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail • Paiement par virement • Stats de base • TCF',
          flatFeePriceId: 'price_1LPhv0B0P8XBvhyKwL0uF1l4',
          label: 'Standard',
          monthlyFlatFee: '149',
          monthlyMinUsage: '20',
          partOfAutoUpscale: 'true',
          services: 'cookies,dpo,processings,contracts'
        },
        nickname: 'Standard monthly (20 published projects/month)',
        product: 'prod_agencyMetered',
        recurring: {
          aggregate_usage: 'max',
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'metered'
        },
        tax_behavior: 'unspecified',
        tiers: [
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 0,
            unit_amount_decimal: '0',
            up_to: 20
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 450,
            unit_amount_decimal: '450',
            up_to: 50
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 250,
            unit_amount_decimal: '250',
            up_to: 100
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 200,
            unit_amount_decimal: '200',
            up_to: 250
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 100,
            unit_amount_decimal: '100',
            up_to: 500
          },
          {
            flat_amount: null,
            flat_amount_decimal: null,
            unit_amount: 20,
            unit_amount_decimal: '20',
            up_to: null
          }
        ],
        tiers_mode: 'graduated',
        transform_quantity: null,
        type: 'recurring',
        unit_amount: null,
        unit_amount_decimal: null
      },
      {
        id: 'price_1LPhv0B0P8XBvhyKwL0uF1l4',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1658819462,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 14900,
            unit_amount_decimal: '14900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          tieredPriceId: 'price_1LPhv0B0P8XBvhyKzJ8gRANW'
        },
        nickname: 'Flat fee for Standard monthly (20 published projects/month)',
        product: 'prod_agencyMetered',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 14900,
        unit_amount_decimal: '14900'
      }
    ]
  },
  {
    id: 'prod_KmuPFEgOh5JSxO',
    metadata: {
      backoffice_agency: 'custom',
      basedOnProduct: 'KING_SIZE',
      description_EN: 'Unlimited projects. Complete graphic customization. Hosting of proofs of consent on Axeptio\'s servers, capped at 300 000 proofs of consent per month.',
      description_FR: 'Nombre de projets illimités. Personnalisation graphique complète. Hébergement des preuves de consentement sur les serveurs d\'Axeptio, plafonné à 300 000 preuves de consentement par mois.',
      features: 'customization',
      services: 'cookies, processings, contracts, dpo, portability',
      statsAllowedAggregateBy: 'day,week',
      statsAreFiltersAllowed: 'false',
      statsPeriod: 'lastWeek',
      statsWidgets: 'dailyOverview',
      title_EN: 'Agency Custom Dedicated',
      title_FR: 'Offre Agence Sur Mesure',
      zd_priority: 'high'
    },
    active: false,
    name: 'Offre Agence sur Mesure',
    type: 'service',
    prices: [
      {
        id: 'price_1Of0v6B0P8XBvhyK7N3o4oih',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1706796432,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 60000,
            unit_amount_decimal: '60000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          WARNING: 'DO NOT EDIT METADATA !!! 🙏',
          description_EN: 'Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Cookies scanner • Email support • Payment by bank transfer • Complete Analytics • Consent Wall • Dedicated CS • API Provisioning • AB testing',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Audit des traceurs • Support tel/mail • Paiement par virement • Stats complètes • Consent Wall • CS Dédié • Accès API • AB testing',
          features: 'customization, consentWall, cookiesConfigTranslation',
          label: 'Custom Agencies 2023 (unlimited projects)',
          pageViewsLimit: '750000',
          services: 'cookies,dpo,processings,automatorScanner,automatorStylesheet,automatorProvisioning,automatorEmailing',
          statsAllowedAggregateBy: 'day,week,month, year',
          statsAreFiltersAllowed: 'true',
          statsKpi: 'pageview,visitor,newVisitor,consentRate,interactionRate,optInRate,hits,quickBounceRate,consentRateMobile,consentRateDesktop,interactionRateMobile,interactionRateDesktop',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview,dailyVisitsOverview,consentDistribution,visitsByHosts,consentDistributionByDevice,optInRateByDevice,devicesDistribution,bounceRateByDuration',
          zd_priority: 'high'
        },
        nickname: 'Custom Agency 2023 (unlimited projects)',
        product: 'prod_KmuPFEgOh5JSxO',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 60000,
        unit_amount_decimal: '60000'
      },
      {
        id: 'Offre-Entreprise-un-million-pages-vues',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1653041913,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 249000,
            unit_amount_decimal: '249000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'KINGSIZE'
        },
        nickname: null,
        product: 'prod_KmuPFEgOh5JSxO',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 249000,
        unit_amount_decimal: '249000'
      },
      {
        id: 'price_1L1BqgB0P8XBvhyK39TqWYiT',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1652976314,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 178800,
            unit_amount_decimal: '178800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Nouvelle Offre Agence',
        product: 'prod_KmuPFEgOh5JSxO',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 178800,
        unit_amount_decimal: '178800'
      },
      {
        id: 'price_1KlBc8B0P8XBvhyKuZv8IWdo',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1649162164,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4900,
            unit_amount_decimal: '4900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_KmuPFEgOh5JSxO',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4900,
        unit_amount_decimal: '4900'
      },
      {
        id: 'price_1Kl8z8B0P8XBvhyKHn3Kg0iZ',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1649152058,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 150000,
            unit_amount_decimal: '150000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview,optInRateByDevice,devicesDistribution, visitsByHosts, consentDistributionByDevice, consentDistribution'
        },
        nickname: 'Prix Agence SFI',
        product: 'prod_KmuPFEgOh5JSxO',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 150000,
        unit_amount_decimal: '150000'
      },
      {
        id: 'price_1Kb52MB0P8XBvhyKqkBhNkOI',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1646753602,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 99000,
            unit_amount_decimal: '99000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          backoffice: '3chocos',
          basedOnProduct: 'TRIPLE_CHOCOS',
          description_EN: '< 2M monthly visits • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • email support • Payment by bank transfer • Native application iOs and Andoid',
          description_FR: '< 2M visites par mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail • Paiement par virement • Application native iOs et Andoid',
          maximumMonthlyVisitors: '1000000',
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, optInRateByDevice, devicesDistribution, visitsByHosts, consentDistributionByDevice, consentDistribution',
          zd_priority: 'high'
        },
        nickname: 'Offre Corolle avec consent wall',
        product: 'prod_KmuPFEgOh5JSxO',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 99000,
        unit_amount_decimal: '99000'
      },
      {
        id: 'price_1KYTEpB0P8XBvhyK3H5a2Vcb',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1646131647,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 118800,
            unit_amount_decimal: '118800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Offre agence basique avec 1 M de visites',
        product: 'prod_KmuPFEgOh5JSxO',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 118800,
        unit_amount_decimal: '118800'
      },
      {
        id: 'price_1KRebOB0P8XBvhyKenDXwOCb',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1644507034,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 264000,
            unit_amount_decimal: '264000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: '\ntrue',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,devicesDistribution,consentDistributionByDevice, consentDistribution, dailyRates'
        },
        nickname: 'Offre à 220€/mois avec taux d\'axeptation par configuration',
        product: 'prod_KmuPFEgOh5JSxO',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 264000,
        unit_amount_decimal: '264000'
      },
      {
        id: 'Offre-220mois-2M-visites',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1643884324,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 264000,
            unit_amount_decimal: '264000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: '\nlastYear',
          statsWidgets: '\ndailyOverview, optInRate, dailyVisitsOverview,optInRateByDevice,devicesDistribution, visitsByHosts, consentDistributionByDevice, consentDistribution'
        },
        nickname: null,
        product: 'prod_KmuPFEgOh5JSxO',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 264000,
        unit_amount_decimal: '264000'
      },
      {
        id: 'Groupe-Tisserin',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1642606093,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 99000,
            unit_amount_decimal: '99000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'CHOCOLAT',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview, optInRate'
        },
        nickname: 'Offre spéciale groupe Tisserin',
        product: 'prod_KmuPFEgOh5JSxO',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 99000,
        unit_amount_decimal: '99000'
      },
      {
        id: 'price_1KHWGCB0P8XBvhyK3ZCqZk9m',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1642091688,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 720000,
            unit_amount_decimal: '720000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'DEUX_CHOCOS',
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,devicesDistribution,consentDistributionByDevice, consentDistribution, dailyRates'
        },
        nickname: 'Offre Spéciale groupe SFA avec Stats',
        product: 'prod_KmuPFEgOh5JSxO',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 720000,
        unit_amount_decimal: '720000'
      },
      {
        id: 'price_1KHRPgB0P8XBvhyKX4RK3K8W',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1642073056,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 310000,
            unit_amount_decimal: '310000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'DEUX_CHOCOS',
          statsAllowedAggregateBy: 'hour,day,week,month',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastMonth',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, consentDistribution\n'
        },
        nickname: 'offre remise spéciale Invivo',
        product: 'prod_KmuPFEgOh5JSxO',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 310000,
        unit_amount_decimal: '310000'
      },
      {
        id: 'price_1KHRGpB0P8XBvhyKFt8cRB5y',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1642072507,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 372000,
            unit_amount_decimal: '372000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Offre remise spéciale Groupe Invivo',
        product: 'prod_KmuPFEgOh5JSxO',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 372000,
        unit_amount_decimal: '372000'
      },
      {
        id: 'price_1K9YjNB0P8XBvhyKi7Bv8IPs',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1640194561,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 118800,
            unit_amount_decimal: '118800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'CHOCOLAT',
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,devicesDistribution,consentDistributionByDevice, consentDistribution, dailyRates'
        },
        nickname: 'Offre agency Standard full Stats',
        product: 'prod_KmuPFEgOh5JSxO',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 118800,
        unit_amount_decimal: '118800'
      },
      {
        id: 'price_1K7Kf9B0P8XBvhyKiqlFuJvP',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1639663827,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 118800,
            unit_amount_decimal: '118800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'DEUX_CHOCOS',
          statsAllowedAggregateBy: 'hour,day,week,month',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, consentDistribution'
        },
        nickname: 'Offre sur Mesure Temporaire incluant les stats',
        product: 'prod_KmuPFEgOh5JSxO',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 118800,
        unit_amount_decimal: '118800'
      },
      {
        id: 'price_1K7KaoB0P8XBvhyKzzM9n09e',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1639663558,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9900,
            unit_amount_decimal: '9900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'CHOCOLAT'
        },
        nickname: 'Offre Spécifique pour les agences',
        product: 'prod_KmuPFEgOh5JSxO',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9900,
        unit_amount_decimal: '9900'
      }
    ]
  },
  {
    id: 'prod_N19VTJQ8vjM9PQ',
    metadata: {},
    active: true,
    name: 'Technical Integration',
    type: 'service',
    prices: [
      {
        id: 'price_1NVAsEB0P8XBvhyKcyeC9XJd',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1689675078,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 80000,
            unit_amount_decimal: '80000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_N19VTJQ8vjM9PQ',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 80000,
        unit_amount_decimal: '80000'
      },
      {
        id: 'price_1NDm7mB0P8XBvhyKLJsOXwso',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1685528366,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 160000,
            unit_amount_decimal: '160000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_N19VTJQ8vjM9PQ',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 160000,
        unit_amount_decimal: '160000'
      },
      {
        id: 'price_1MH7CNB0P8XBvhyKvt3eQ07i',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1671548023,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9900,
            unit_amount_decimal: '9900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_N19VTJQ8vjM9PQ',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 9900,
        unit_amount_decimal: '9900'
      }
    ]
  },
  {
    id: 'prod_NaI6NZouW66gAX',
    metadata: {},
    active: true,
    name: 'Prestation GTM',
    type: 'service',
    prices: [
      {
        id: 'price_1Mp7W2B0P8XBvhyKa93amfnw',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1679652394,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 60000,
            unit_amount_decimal: '60000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_NaI6NZouW66gAX',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 60000,
        unit_amount_decimal: '60000'
      }
    ]
  },
  {
    id: 'prod_MyQxFvtJ4cMY0B',
    metadata: {
      services: 'contractsV2'
    },
    active: false,
    name: 'Contractual Consent Management (Terms)',
    type: 'service',
    prices: [
      {
        id: 'price_1MEU8XB0P8XBvhyKyOJwKxh0',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1670921213,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Contracts V2 yearly trial',
        product: 'prod_MyQxFvtJ4cMY0B',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      },
      {
        id: 'price_1MEU85B0P8XBvhyKlYA0W3by',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1670921185,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Contracts V2 monthly trial',
        product: 'prod_MyQxFvtJ4cMY0B',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      }
    ]
  },
  {
    id: 'prod_MAfrPEWPkbyPIz',
    metadata: {},
    active: true,
    name: 'Commissionnement PrestaStore',
    type: 'service',
    prices: [
      {
        id: 'price_1MEA6fB0P8XBvhyKZvtDQtSu',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1670844217,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 151774,
            unit_amount_decimal: '151774'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_MAfrPEWPkbyPIz',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 151774,
        unit_amount_decimal: '151774'
      },
      {
        id: 'price_1LSKW0B0P8XBvhyKGxmnX5bW',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1659444484,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 392017,
            unit_amount_decimal: '392017'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_MAfrPEWPkbyPIz',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 392017,
        unit_amount_decimal: '392017'
      }
    ]
  },
  {
    id: 'prod_FazerHyEh11FBm',
    metadata: {
      features: 'customization',
      services: 'cookies',
      statsAllowedAggregateBy: 'day,week',
      statsAreFiltersAllowed: 'false',
      statsPeriod: 'lastWeek',
      statsWidgets: 'dailyOverview',
      zd_priority: 'normal'
    },
    active: false,
    name: 'Offre Entreprise sur mesure',
    type: 'service',
    prices: [
      {
        id: 'price_1LcPkjB0P8XBvhyKMpjC2BAx',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1661847897,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 58800,
            unit_amount_decimal: '58800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'KING_SIZE',
          description_FR: 'Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail',
          isCustomProjectProductPrice: 'true',
          services: 'cookies, processings, contracts, dpo, portability',
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,devicesDistribution,consentDistributionByDevice, consentDistribution, dailyRates'
        },
        nickname: 'Cookies Herta',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 58800,
        unit_amount_decimal: '58800'
      },
      {
        id: 'price_1LKdqEB0P8XBvhyKcZRI4xIC',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1657612150,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 150000,
            unit_amount_decimal: '150000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'DEUX_CHOCOS',
          description_EN: 'Up to  2M page views/month • Unlimited cookies • Multilingual • Storage of consents • email support • Payment by bank transfer • Graphic customization • Complete stats',
          description_FR: '< 2M de pages vues/mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Support tel/mail • Stats complètes',
          isCustomProjectProductPrice: 'true',
          services: 'cookies',
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, consentDistribution, optInRateByDevice, devicesDistribution, consentDistributionByDevice, optInRateByConfig, dailyRates'
        },
        nickname: 'Offre Enterprise Light (que cookies, 2M/pages vues mensuelles)',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 150000,
        unit_amount_decimal: '150000'
      },
      {
        id: 'price_1LAwZwB0P8XBvhyKlG9eUoqE',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1655300896,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 299000,
            unit_amount_decimal: '299000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'KINGSIZE',
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, consentDistribution, optInRateByDevice, devicesDistribution, consentDistributionByDevice, optInRateByConfig, dailyRates'
        },
        nickname: null,
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 299000,
        unit_amount_decimal: '299000'
      },
      {
        id: 'price_1L8K9eB0P8XBvhyKxc8MU27W',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1654676538,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 249000,
            unit_amount_decimal: '249000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'KING_SIZE',
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, consentDistribution, optInRateByDevice, devicesDistribution, consentDistributionByDevice, optInRateByConfig, dailyRates'
        },
        nickname: 'yearly 500k page views/month',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 249000,
        unit_amount_decimal: '249000'
      },
      {
        id: 'price_1L5YeOB0P8XBvhyKsVGJUIzF',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1654017276,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 149100,
            unit_amount_decimal: '149100'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          'basedOnProduct ': 'CHOCOLAT',
          description_FR: '< 1.5 million de visites par mois • Cookies illimités',
          isCustomProjectProductPrice: 'true',
          services: 'cookies'
        },
        nickname: null,
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 3,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 149100,
        unit_amount_decimal: '149100'
      },
      {
        id: 'price_1KvKPLB0P8XBvhyKMlr3QJaz',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1651579247,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 158600,
            unit_amount_decimal: '158600'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'TRIPLE_CHOCOS',
          description_FR: '< 1 million de visites par mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel ',
          isCustomProjectProductPrice: 'true',
          services: 'cookies, processings, contracts, dpo, portability',
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, consentDistribution, optInRateByDevice, devicesDistribution, consentDistributionByDevice, optInRateByConfig, dailyRates'
        },
        nickname: null,
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 158600,
        unit_amount_decimal: '158600'
      },
      {
        id: 'price_1KjRRrB0P8XBvhyKJpQjW4Or',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1648746375,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 425000,
            unit_amount_decimal: '425000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Gestion des cookies - Offre Century 21',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 425000,
        unit_amount_decimal: '425000'
      },
      {
        id: 'price_1KdDB9B0P8XBvhyKQ8zBbjp0',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1647261555,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9900,
            unit_amount_decimal: '9900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'TRIPLE_CHOCOS',
          description_EN: '< 2M monthly visits • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • email support • Payment by bank transfer • Native application iOs and Andoid',
          description_FR: '< 2M visites par mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail • Paiement par virement • Application native iOs et Andoid',
          isCustomProjectProductPrice: 'true',
          services: 'cookies,processings,dpo,contracts,portability',
          statsAllowedAggregateBy: 'hour,day,week,month,year\n',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, optInRateByDevice, devicesDistribution, visitsByHosts, consentDistributionByDevice, consentDistribution, interactionRate'
        },
        nickname: 'Triple chocos avec taux d\'interaction',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9900,
        unit_amount_decimal: '9900'
      },
      {
        id: 'price_1Kc994B0P8XBvhyK4RyeeDU4',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1647007722,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 15000,
            unit_amount_decimal: '15000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'CHOCOLAT',
          description_EN: '< 150K monthly visits • Unlimited cookies • Graphic customization • Multilingual • Storage of consents',
          description_FR: '< 150K visites par mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements',
          isCustomProjectProductPrice: 'true',
          services: 'cookies',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview,dailyVisitsOverview,optInRate,interactionRate'
        },
        nickname: 'Cookies (ancienne formule) avec taux d\'interaction débloqué',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 15000,
        unit_amount_decimal: '15000'
      },
      {
        id: 'price_1KYWiGB0P8XBvhyK3Gb6sIeL',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1646145004,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 18000,
            unit_amount_decimal: '18000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'DEUX_CHOCOS',
          description_FR: '< 300K visites par mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail • Ajout des Stats pour le Groupe Brandt',
          isCustomProjectProductPrice: 'true',
          services: 'cookies, processings, contracts, dpo\n',
          statsAllowedAggregateBy: 'hour,day,week,month,year\n',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,devicesDistribution,consentDistributionByDevice, consentDistribution, dailyRates\n'
        },
        nickname: 'Cookie avec ajout des Stats',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 18000,
        unit_amount_decimal: '18000'
      },
      {
        id: 'price_1KO1K3B0P8XBvhyKftImWfpR',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1643641059,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 200000,
            unit_amount_decimal: '200000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Abonnement gestion des consentements contractuels pour 5 Millions de consentements',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 200000,
        unit_amount_decimal: '200000'
      },
      {
        id: 'price_1KO1IlB0P8XBvhyKnV8TFiO8',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1643640979,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1400000,
            unit_amount_decimal: '1400000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          features: 'customization',
          services: 'contracts,contractsV2'
        },
        nickname: 'Licence sur-mesure comutitres : La gestion des consentements contractuels nouvelle version',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1400000,
        unit_amount_decimal: '1400000'
      },
      {
        id: 'price_1KJHbjB0P8XBvhyKV3s6RJ6H',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1642512019,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 216000,
            unit_amount_decimal: '216000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'DEUX_CHOCOS',
          description_FR: '< 1 million de visites par mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail',
          isCustomProjectProductPrice: 'true',
          services: 'cookies, processings, contracts, dpo, portability',
          statsAllowedAggregateBy: 'hour,day,week,month',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastMonth',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, consentDistribution'
        },
        nickname: 'Offre Spéciale Exaprint',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 216000,
        unit_amount_decimal: '216000'
      },
      {
        id: 'price_1KHRS7B0P8XBvhyKo7IRo6W5',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1642073207,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 310000,
            unit_amount_decimal: '310000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Offre remise spéciale Invivo',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 310000,
        unit_amount_decimal: '310000'
      },
      {
        id: 'price_1K9rhdB0P8XBvhyKlg8wGRZL',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1640267489,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 225000,
            unit_amount_decimal: '225000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'KING_SIZE',
          description_FR: '< 3 Millions de visites par mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail',
          isCustomProjectProductPrice: 'true',
          services: 'cookies, processings, contracts, dpo, portability, mobile',
          statsAllowedAggregateBy: 'hour,day,week,month,year\n',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear\n',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,devicesDistribution,consentDistributionByDevice, consentDistribution, dailyRates\n'
        },
        nickname: 'Offre Spéciale Orchestra avec Stats et 3 Millions de visites',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 225000,
        unit_amount_decimal: '225000'
      },
      {
        id: 'price_1K7LFSB0P8XBvhyKXNL0rFgC',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1639666078,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 170000,
            unit_amount_decimal: '170000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Offre Spécifique Invivo : option complémentaire pour étendre le périmètre de 40 sites à 80 sites',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 170000,
        unit_amount_decimal: '170000'
      },
      {
        id: 'Offre-Agence-sur-mesure-Odiens',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1639495362,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 264000,
            unit_amount_decimal: '264000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: '\ntrue',
          statsPeriod: 'lastYear',
          statsWidgets: '\ndailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,devicesDistribution,consentDistributionByDevice, consentDistribution, dailyRates'
        },
        nickname: 'Offre à 220€/mois avec le taux d\'axeptation par configuration en plus',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 264000,
        unit_amount_decimal: '264000'
      },
      {
        id: 'price_1K6FkcB0P8XBvhyKAyl1NDmh',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1639406618,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 30000,
            unit_amount_decimal: '30000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'KING_SIZE',
          description_FR: '< 4 Millions de visites par mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail',
          isCustomProjectProductPrice: 'true',
          services: 'cookies, processings, contracts, dpo, portability, mobile',
          statsAllowedAggregateBy: 'hour,day,week,month,year\n',
          statsAreFiltersAllowed: 'true\n',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,devicesDistribution,consentDistributionByDevice, consentDistribution, dailyRates\n'
        },
        nickname: 'Offre Entreprise King Size 4M',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 30000,
        unit_amount_decimal: '30000'
      },
      {
        id: 'price_1IDvlYB0P8XBvhyKVxyil8Cc',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1611683748,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 360000,
            unit_amount_decimal: '360000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Offre sur Mesure',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 360000,
        unit_amount_decimal: '360000'
      },
      {
        id: 'agency_dedicated_yearly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1565417129,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 150000,
            unit_amount_decimal: '150000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'CHOCOLAT',
          services: 'cookies, processings, contracts, dpo, portability',
          statsAllowedAggregateBy: 'hour,day,week,month',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastMonth',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, consentDistribution'
        },
        nickname: 'Agency Dedicated Yearly',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: 15,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 150000,
        unit_amount_decimal: '150000'
      },
      {
        id: 'agency_dedicated_monthly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1565416997,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 15000,
            unit_amount_decimal: '15000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'DEUX_CHOCOS',
          services: 'cookies, processings, contracts, dpo, portability',
          statsAllowedAggregateBy: 'hour,day,week,month',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastMonth',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, consentDistribution'
        },
        nickname: 'Agency Dedicated Monthly',
        product: 'prod_FazerHyEh11FBm',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: 15,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 15000,
        unit_amount_decimal: '15000'
      }
    ]
  },
  {
    id: 'prod_IAFqZKPaV024ev',
    metadata: {},
    active: true,
    name: 'Support Prioritaire Grand compte',
    type: 'service',
    prices: [
      {
        id: 'price_1LcPgtB0P8XBvhyK98rApsK4',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1661847659,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 425000,
            unit_amount_decimal: '425000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_IAFqZKPaV024ev',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 425000,
        unit_amount_decimal: '425000'
      },
      {
        id: 'price_1LV8q1B0P8XBvhyK3hI2L4PO',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1660114581,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 85000,
            unit_amount_decimal: '85000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_IAFqZKPaV024ev',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 85000,
        unit_amount_decimal: '85000'
      },
      {
        id: 'price_1L5YkpB0P8XBvhyK9leZ4Dnh',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1654017675,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 25000,
            unit_amount_decimal: '25000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_IAFqZKPaV024ev',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 3,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 25000,
        unit_amount_decimal: '25000'
      },
      {
        id: 'price_1KjRVAB0P8XBvhyKNNTbDtlC',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1648746580,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 180000,
            unit_amount_decimal: '180000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Support prioritaire',
        product: 'prod_IAFqZKPaV024ev',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 180000,
        unit_amount_decimal: '180000'
      },
      {
        id: 'price_1KO1LiB0P8XBvhyKj34dDn4P',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1643641162,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 187000,
            unit_amount_decimal: '187000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Ce prix comporte une réduction de 15% accordée spécialement pour le projet Navigo',
        product: 'prod_IAFqZKPaV024ev',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 187000,
        unit_amount_decimal: '187000'
      },
      {
        id: 'price_1K07o9B0P8XBvhyKrSClrR6D',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1637946117,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 25000,
            unit_amount_decimal: '25000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_IAFqZKPaV024ev',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 25000,
        unit_amount_decimal: '25000'
      },
      {
        id: 'price_1IeNT9B0P8XBvhyKQIx0Cc4Q',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1617986767,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 10000,
            unit_amount_decimal: '10000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_IAFqZKPaV024ev',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 10000,
        unit_amount_decimal: '10000'
      },
      {
        id: 'price_1IZIsvB0P8XBvhyKSocxqleI',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1616777505,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 100000,
            unit_amount_decimal: '100000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_IAFqZKPaV024ev',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 100000,
        unit_amount_decimal: '100000'
      },
      {
        id: 'price_1IUW7kB0P8XBvhyK0ZskjdyM',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1615636756,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_IAFqZKPaV024ev',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 300000,
        unit_amount_decimal: '300000'
      },
      {
        id: 'price_1IUVxaB0P8XBvhyKpEt25vVb',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1615636126,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 52000,
            unit_amount_decimal: '52000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_IAFqZKPaV024ev',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 52000,
        unit_amount_decimal: '52000'
      },
      {
        id: 'price_1IP9RPB0P8XBvhyKFOmohhfG',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1614357923,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 60000,
            unit_amount_decimal: '60000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Support Prioritaire',
        product: 'prod_IAFqZKPaV024ev',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 60000,
        unit_amount_decimal: '60000'
      },
      {
        id: 'price_1I183yB0P8XBvhyKVZnX72va',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1608632754,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 180000,
            unit_amount_decimal: '180000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_IAFqZKPaV024ev',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 180000,
        unit_amount_decimal: '180000'
      },
      {
        id: 'price_1Hw33UB0P8XBvhyKEG3eVXiS',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1607421864,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 85000,
            unit_amount_decimal: '85000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_IAFqZKPaV024ev',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 85000,
        unit_amount_decimal: '85000'
      },
      {
        id: 'price_1HZvKsB0P8XBvhyKFBCNKTKM',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1602148974,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 50000,
            unit_amount_decimal: '50000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_IAFqZKPaV024ev',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 50000,
        unit_amount_decimal: '50000'
      }
    ]
  },
  {
    id: 'prod_JltmM8jR5HutKC',
    metadata: {
      backoffice: 'choco',
      description_EN: '< 150K monthly visits • Unlimited cookies • Graphic customization • Multilingual • Storage of consents',
      description_FR: '< 150K visites par mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements',
      features: 'customization',
      maximumMonthlyVisitors: '150000',
      services: 'cookies',
      statsAllowedAggregateBy: 'day,week',
      statsAreFiltersAllowed: 'false',
      statsPeriod: 'lastWeek',
      statsWidgets: 'dailyOverview,optInRate',
      zd_priority: 'normal'
    },
    active: false,
    name: 'Chocolat',
    type: 'service',
    prices: [
      {
        id: 'price_1LKcjNB0P8XBvhyKdsF9m3HU',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1657607881,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500,
            unit_amount_decimal: '1500'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JltmM8jR5HutKC',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 1500,
        unit_amount_decimal: '1500'
      },
      {
        id: 'price_1KT3QOB0P8XBvhyKLqoDmPd5',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1644840780,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 18000,
            unit_amount_decimal: '18000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JltmM8jR5HutKC',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 18000,
        unit_amount_decimal: '18000'
      },
      {
        id: 'price_1JezQrB0P8XBvhyKRu2lapFd',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1632909033,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 7500,
            unit_amount_decimal: '7500'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JltmM8jR5HutKC',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 5,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 7500,
        unit_amount_decimal: '7500'
      },
      {
        id: 'price_1J8LzAB0P8XBvhyK2SnIVJB8',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1625130904,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500,
            unit_amount_decimal: '1500'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Chocolat €15/mo',
        product: 'prod_JltmM8jR5HutKC',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500,
        unit_amount_decimal: '1500'
      },
      {
        id: 'price_1J8LzAB0P8XBvhyK66pKx5Ev',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1625130904,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 18000,
            unit_amount_decimal: '18000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Chocolat €180/yr',
        product: 'prod_JltmM8jR5HutKC',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 18000,
        unit_amount_decimal: '18000'
      }
    ]
  },
  {
    id: 'prod_LmgpW54pvJnH7Q',
    metadata: {},
    active: true,
    name: 'Création illustration',
    type: 'service',
    prices: [
      {
        id: 'price_1L57SBB0P8XBvhyKkPrYHwRv',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1653912731,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 20000,
            unit_amount_decimal: '20000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_LmgpW54pvJnH7Q',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 20000,
        unit_amount_decimal: '20000'
      }
    ]
  },
  {
    id: 'prod_Lf8ANEDvkgBF4H',
    metadata: {},
    active: false,
    name: 'Offre Entreprise sur mesure • Offre Spéciale Exaprint',
    type: 'service',
    prices: [
      {
        id: 'price_1KxnuWB0P8XBvhyKRMnKtNkv',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1652169312,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 180000,
            unit_amount_decimal: '180000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_Lf8ANEDvkgBF4H',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 180000,
        unit_amount_decimal: '180000'
      }
    ]
  },
  {
    id: 'prod_FazfmnjscKbeHe',
    metadata: {
      backoffice_agency: 'standard',
      basedOnProduct: 'KING_SIZE',
      description_EN: 'Unlimited projects. Complete graphic customization. Hosting of proofs of consent on Axeptio\'s servers, capped at 300 000 proofs of consent per month.',
      description_FR: 'Nombre de projets illimités. Personnalisation graphique complète. Hébergement des preuves de consentement sur les serveurs d\'Axeptio, plafonné à 300 000 preuves de consentement par mois.',
      features: 'customization,consentWall',
      services: 'cookies, processings, contracts, dpo, portability',
      statsAllowedAggregateBy: 'day,week',
      statsAreFiltersAllowed: 'false',
      statsPeriod: 'lastWeek',
      statsWidgets: 'dailyOverview',
      title_EN: 'Agency Standard Offer',
      title_FR: 'Offre Agence Standard',
      zd_priority: 'high'
    },
    active: false,
    name: 'Agency Standard',
    type: 'service',
    prices: [
      {
        id: 'Mini-Offre-Agence',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1640615509,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 58800,
            unit_amount_decimal: '58800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Offre agence à 49€/mois pour ceux qui n\'ont pas de budget, mais aiment la solution',
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 58800,
        unit_amount_decimal: '58800'
      },
      {
        id: 'Offre-Agence-Odiens-Stats',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1639499126,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 264000,
            unit_amount_decimal: '264000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,devicesDistribution,consentDistributionByDevice, consentDistribution, dailyRates'
        },
        nickname: 'Offre à 220€/mois avec le taux d\'axeptation par configuration en plus',
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 264000,
        unit_amount_decimal: '264000'
      },
      {
        id: 'trois_et_demi_millions_visites',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1633427260,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 475000,
            unit_amount_decimal: '475000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,devicesDistribution,consentDistributionByDevice, consentDistribution, dailyRates'
        },
        nickname: null,
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 475000,
        unit_amount_decimal: '475000'
      },
      {
        id: 'offre-speciale-stats-annuel',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1633357743,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 180000,
            unit_amount_decimal: '180000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview,optInRateByDevice,devicesDistribution, visitsByHosts, consentDistributionByDevice, consentDistribution'
        },
        nickname: null,
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 180000,
        unit_amount_decimal: '180000'
      },
      {
        id: 'offre-speciale-stats',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1633357720,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 15000,
            unit_amount_decimal: '15000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'DEUX_CHOCOS',
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, consentDistribution, optInRateByDevice, devicesDistribution, consentDistributionByDevice, optInRateByConfig, dailyRates'
        },
        nickname: null,
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 15000,
        unit_amount_decimal: '15000'
      },
      {
        id: '2-et-demi-millions-visites-annuel',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1633353921,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 420000,
            unit_amount_decimal: '420000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,devicesDistribution,consentDistributionByDevice, consentDistribution, dailyRates'
        },
        nickname: null,
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 420000,
        unit_amount_decimal: '420000'
      },
      {
        id: '2-et-demi-millions-visites',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1633353841,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 35000,
            unit_amount_decimal: '35000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview,dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,devicesDistribution,consentDistributionByDevice, consentDistribution, dailyRates'
        },
        nickname: null,
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 35000,
        unit_amount_decimal: '35000'
      },
      {
        id: '2-millions-visites-annuel',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1633353776,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 360000,
            unit_amount_decimal: '360000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,devicesDistribution,consentDistributionByDevice, consentDistribution, dailyRates'
        },
        nickname: null,
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 360000,
        unit_amount_decimal: '360000'
      },
      {
        id: '2-millions-visites',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1633353739,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 30000,
            unit_amount_decimal: '30000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,devicesDistribution,consentDistributionByDevice, consentDistribution, dailyRates'
        },
        nickname: null,
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 30000,
        unit_amount_decimal: '30000'
      },
      {
        id: 'un-et-demi-million-visites',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1633353689,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 264000,
            unit_amount_decimal: '264000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview,optInRateByDevice,devicesDistribution, visitsByHosts, consentDistributionByDevice, consentDistribution'
        },
        nickname: null,
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 264000,
        unit_amount_decimal: '264000'
      },
      {
        id: '1-et-demi-million-visites',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1633353472,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 22000,
            unit_amount_decimal: '22000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview,optInRateByDevice,devicesDistribution, visitsByHosts, consentDistributionByDevice, consentDistribution'
        },
        nickname: null,
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 22000,
        unit_amount_decimal: '22000'
      },
      {
        id: '1-Million-Visites',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1633353427,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 180000,
            unit_amount_decimal: '180000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          statsAllowedAggregateBy: 'hour,day,week,month',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastMonth',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, consentDistribution'
        },
        nickname: null,
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 180000,
        unit_amount_decimal: '180000'
      },
      {
        id: 'price_1JdARLB0P8XBvhyKw2yicGwz',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1632474691,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 250000,
            unit_amount_decimal: '250000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          features: 'consentWall'
        },
        nickname: null,
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 250000,
        unit_amount_decimal: '250000'
      },
      {
        id: 'price_1JXSvVB0P8XBvhyKH5vBkl1Q',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1631115785,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 225000,
            unit_amount_decimal: '225000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 225000,
        unit_amount_decimal: '225000'
      },
      {
        id: 'price_1JUWHLB0P8XBvhyKbKKlwTon',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1630413687,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 142560,
            unit_amount_decimal: '142560'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 142560,
        unit_amount_decimal: '142560'
      },
      {
        id: 'price_1IgsGlB0P8XBvhyKPKnaduQc',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1618581819,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 150000,
            unit_amount_decimal: '150000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 150000,
        unit_amount_decimal: '150000'
      },
      {
        id: 'AgencyYearlyFull',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1617106416,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 118800,
            unit_amount_decimal: '118800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'CHOCOLAT',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview'
        },
        nickname: null,
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 118800,
        unit_amount_decimal: '118800'
      },
      {
        id: 'price_1ITqWNB0P8XBvhyKAbZSxHoT',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1615476835,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 360000,
            unit_amount_decimal: '360000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 360000,
        unit_amount_decimal: '360000'
      },
      {
        id: 'price_1I9XdOB0P8XBvhyKltSRsKms',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1610637674,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 200000,
            unit_amount_decimal: '200000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          isProfessional: 'YES',
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview,dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,devicesDistribution,consentDistributionByDevice, consentDistribution, dailyRates'
        },
        nickname: null,
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 200000,
        unit_amount_decimal: '200000'
      },
      {
        id: 'price_1I7Iy7B0P8XBvhyKKc7GMxDf',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1610104643,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 22000,
            unit_amount_decimal: '22000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsPeriod: 'lastYear',
          statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, consentDistribution, optInRateByDevice, devicesDistribution, consentDistributionByDevice, optInRateByConfig, dailyRates'
        },
        nickname: 'Agency Standard Monthly',
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 22000,
        unit_amount_decimal: '22000'
      },
      {
        id: 'agency_standard_yearly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1569243254,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 99000,
            unit_amount_decimal: '99000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'CHOCOLAT',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview'
        },
        nickname: 'Agency Standard Yearly',
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: 15,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 99000,
        unit_amount_decimal: '99000'
      },
      {
        id: 'agency_standard_monthly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1565417077,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9900,
            unit_amount_decimal: '9900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          basedOnProduct: 'CHOCOLAT',
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview'
        },
        nickname: 'Agency Standard Monthly',
        product: 'prod_FazfmnjscKbeHe',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: 15,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9900,
        unit_amount_decimal: '9900'
      }
    ]
  },
  {
    id: 'prod_JfBpWbTdJ6nwjJ',
    metadata: {},
    active: false,
    name: 'Dépassement d\'abonnement',
    type: 'service',
    prices: [
      {
        id: 'price_1K8os8B0P8XBvhyKH3OhT1Y9',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1640018280,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 29700,
            unit_amount_decimal: '29700'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JfBpWbTdJ6nwjJ',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 29700,
        unit_amount_decimal: '29700'
      },
      {
        id: 'price_1J1rRaB0P8XBvhyKed9Q7gIl',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623583534,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 20000,
            unit_amount_decimal: '20000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Dépassement d\'abonnement',
        product: 'prod_JfBpWbTdJ6nwjJ',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 20000,
        unit_amount_decimal: '20000'
      }
    ]
  },
  {
    id: 'prod_KoRKiEfYTzTUXh',
    metadata: {
      OBJECT_ID: '1345660227',
      OBJECT_SOURCE: 'PRODUCTS',
      hs_portal_id: '4424028'
    },
    active: false,
    name: 'Chocolat',
    type: 'service',
    prices: [
      {
        id: 'plan_KoRKFGgSywgFYM',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1640016634,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500,
            unit_amount_decimal: '1500'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_KoRKiEfYTzTUXh',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500,
        unit_amount_decimal: '1500'
      }
    ]
  },
  {
    id: 'prod_KkTZfxuMHnzefA',
    metadata: {
      OBJECT_ID: '2357088035',
      OBJECT_SOURCE: 'LINE_ITEMS',
      hs_portal_id: '4424028'
    },
    active: false,
    name: 'Abonnement Deux Chocos',
    type: 'service',
    prices: [
      {
        id: 'plan_KkTZciyBz1OEoV',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1639102416,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4900,
            unit_amount_decimal: '4900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_KkTZfxuMHnzefA',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4900,
        unit_amount_decimal: '4900'
      }
    ]
  },
  {
    id: 'prod_KjteHSkjh73Ugz',
    metadata: {
      OBJECT_ID: '2354327872',
      OBJECT_SOURCE: 'LINE_ITEMS',
      hs_portal_id: '4424028'
    },
    active: false,
    name: 'Abonnement Deux Chocos',
    type: 'service',
    prices: [
      {
        id: 'plan_KjteeGzy4g84iQ',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1638968793,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4900,
            unit_amount_decimal: '4900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_KjteHSkjh73Ugz',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4900,
        unit_amount_decimal: '4900'
      }
    ]
  },
  {
    id: 'prod_KjspV5aD5nfsij',
    metadata: {
      OBJECT_ID: '2354359100',
      OBJECT_SOURCE: 'LINE_ITEMS',
      hs_portal_id: '4424028'
    },
    active: false,
    name: 'Abonnement Deux Chocos',
    type: 'service',
    prices: [
      {
        id: 'plan_Kjspe74kkmKTvD',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1638965775,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4900,
            unit_amount_decimal: '4900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_KjspV5aD5nfsij',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4900,
        unit_amount_decimal: '4900'
      }
    ]
  },
  {
    id: 'prod_Kj64fSe1ZDqbBR',
    metadata: {
      OBJECT_ID: '2346473588',
      OBJECT_SOURCE: 'LINE_ITEMS',
      hs_portal_id: '4424028'
    },
    active: false,
    name: 'Devis Formule Tripe chocos',
    type: 'service',
    prices: [
      {
        id: 'plan_Kj64kz4kw5WO5E',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1638784372,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 118800,
            unit_amount_decimal: '118800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_Kj64fSe1ZDqbBR',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 118800,
        unit_amount_decimal: '118800'
      }
    ]
  },
  {
    id: 'prod_KhJSH23HA071wC',
    metadata: {
      OBJECT_ID: '39842712',
      OBJECT_SOURCE: 'PRODUCTS',
      hs_portal_id: '4424028'
    },
    active: false,
    name: 'Licence Agence Illimité',
    type: 'service',
    prices: [
      {
        id: 'plan_KhJSQVavZRQsuY',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1638372867,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 118800,
            unit_amount_decimal: '118800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_KhJSH23HA071wC',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 118800,
        unit_amount_decimal: '118800'
      }
    ]
  },
  {
    id: 'prod_KfROu8N1YcfEgR',
    metadata: {
      OBJECT_ID: '2304187447',
      OBJECT_SOURCE: 'LINE_ITEMS',
      hs_portal_id: '4424028'
    },
    active: false,
    name: 'Cookies',
    type: 'service',
    prices: [
      {
        id: 'plan_KfROUSTvq4RHnk',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1637941120,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500,
            unit_amount_decimal: '1500'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_KfROu8N1YcfEgR',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500,
        unit_amount_decimal: '1500'
      }
    ]
  },
  {
    id: 'prod_JltnnHV2lSF05d',
    metadata: {
      backoffice: 'kingsize',
      description_EN: '< 2M monthly visits • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • email support • Payment by bank transfer • Native application iOs and Andoid',
      description_FR: '< 2M visites par mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail • Paiement par virement • Application native iOs et Andoid',
      features: 'customization, consentWall',
      maximumMonthlyVisitors: '2000000',
      services: 'cookies, processings,contracts,dpo, portability',
      statsAllowedAggregateBy: 'hour,day,week,month,year',
      statsAreFiltersAllowed: 'true',
      statsPeriod: 'lastYear',
      statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, consentDistribution, optInRateByDevice, devicesDistribution, consentDistributionByDevice, optInRateByConfig, dailyRates',
      zd_priority: 'high'
    },
    active: false,
    name: 'King size',
    type: 'service',
    prices: [
      {
        id: 'price_1JkWImB0P8XBvhyKYYDx3wXu',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1634227024,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 298800,
            unit_amount_decimal: '298800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'King Size 3 Millions',
        product: 'prod_JltnnHV2lSF05d',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 298800,
        unit_amount_decimal: '298800'
      },
      {
        id: 'price_1J8LzpB0P8XBvhyKArcFVHUD',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1625130945,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 19900,
            unit_amount_decimal: '19900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'King Size €199/mo',
        product: 'prod_JltnnHV2lSF05d',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 19900,
        unit_amount_decimal: '19900'
      },
      {
        id: 'price_1J8LzpB0P8XBvhyKXXt1aii7',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1625130945,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 238800,
            unit_amount_decimal: '238800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'King Size €2388/yr',
        product: 'prod_JltnnHV2lSF05d',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 238800,
        unit_amount_decimal: '238800'
      }
    ]
  },
  {
    id: 'prod_EK56LUOwFsZy8Y',
    metadata: {
      offers: 'standard,enterprise',
      service: 'cookies',
      services: 'cookies',
      statsAllowedAggregateBy: 'day,week',
      statsAreFiltersAllowed: 'false',
      statsPeriod: 'lastWeek',
      statsWidgets: 'dailyOverview, dailyVisitsOverview'
    },
    active: false,
    name: 'Cookies',
    type: 'service',
    prices: [
      {
        id: 'price_1Jgq3cB0P8XBvhyK3DY7eoGS',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1633349652,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 420000,
            unit_amount_decimal: '420000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 420000,
        unit_amount_decimal: '420000'
      },
      {
        id: 'price_1JMYB1B0P8XBvhyKwtznp3EE',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1628514359,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 700000,
            unit_amount_decimal: '700000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 700000,
        unit_amount_decimal: '700000'
      },
      {
        id: 'price_1JCj6MB0P8XBvhyKooOFKwDf',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1626173074,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 324000,
            unit_amount_decimal: '324000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          services: 'cookies, processings,contracts,dpo, portability,contractsV2'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 324000,
        unit_amount_decimal: '324000'
      },
      {
        id: 'price_1J1rLBB0P8XBvhyKF9HnOr5H',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623583137,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 39600,
            unit_amount_decimal: '39600'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: 'Offre Triple Chocos Annuelle',
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 39600,
        unit_amount_decimal: '39600'
      },
      {
        id: 'price_1J1qtnB0P8XBvhyKPPLA8QGA',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623581439,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1634,
            unit_amount_decimal: '1634'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal'
        },
        nickname: 'Offre deux chocos Mensuelle',
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1634,
        unit_amount_decimal: '1634'
      },
      {
        id: 'Deux-chocos-mensuel',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623141638,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4900,
            unit_amount_decimal: '4900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4900,
        unit_amount_decimal: '4900'
      },
      {
        id: 'price_1IxqOpB0P8XBvhyKDBgXsTJX',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1622626207,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 19600,
            unit_amount_decimal: '19600'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal'
        },
        nickname: 'Offre 2 Chocos',
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 19600,
        unit_amount_decimal: '19600'
      },
      {
        id: 'Cookies7Sites',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1620659275,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 126000,
            unit_amount_decimal: '126000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 126000,
        unit_amount_decimal: '126000'
      },
      {
        id: 'KingSizeYearly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1620655932,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 238800,
            unit_amount_decimal: '238800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 238800,
        unit_amount_decimal: '238800'
      },
      {
        id: 'price_1IlHmiB0P8XBvhyKujZGvMGf',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1619633212,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 360000,
            unit_amount_decimal: '360000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 360000,
        unit_amount_decimal: '360000'
      },
      {
        id: 'price_1IlD6qB0P8XBvhyKV64cjB8k',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1619615240,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 19900,
            unit_amount_decimal: '19900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: 'King Size',
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 19900,
        unit_amount_decimal: '19900'
      },
      {
        id: 'price_1IkVanB0P8XBvhyKwhMRBLSq',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1619447961,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 18000,
            unit_amount_decimal: '18000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 18000,
        unit_amount_decimal: '18000'
      },
      {
        id: 'price_1IjRHsB0P8XBvhyKNUaciLAz',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1619193084,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 58800,
            unit_amount_decimal: '58800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 58800,
        unit_amount_decimal: '58800'
      },
      {
        id: 'price_1IgZMQB0P8XBvhyKD3TX3pFx',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1618509134,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 118800,
            unit_amount_decimal: '118800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 118800,
        unit_amount_decimal: '118800'
      },
      {
        id: 'price_1IdvdxB0P8XBvhyK2MdSYqhV',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1617879805,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 29000,
            unit_amount_decimal: '29000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 29000,
        unit_amount_decimal: '29000'
      },
      {
        id: 'price_1IdIElB0P8XBvhyKAJbzASK2',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1617728327,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 220000,
            unit_amount_decimal: '220000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          statsAllowedAggregateBy: 'day,week',
          statsAreFiltersAllowed: 'false',
          statsPeriod: 'lastWeek',
          statsWidgets: 'dailyOverview,optInRate',
          zd_priority: 'high'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 220000,
        unit_amount_decimal: '220000'
      },
      {
        id: 'price_1IZIrXB0P8XBvhyKdsjE74Tb',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1616777419,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 525000,
            unit_amount_decimal: '525000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 525000,
        unit_amount_decimal: '525000'
      },
      {
        id: 'price_1IYxjrB0P8XBvhyKnSgMnm3i',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1616696219,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 30000,
            unit_amount_decimal: '30000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 30000,
        unit_amount_decimal: '30000'
      },
      {
        id: 'price_1IW1HGB0P8XBvhyKDlhmZshv',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1615994838,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 22000,
            unit_amount_decimal: '22000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 22000,
        unit_amount_decimal: '22000'
      },
      {
        id: 'price_1IVi0FB0P8XBvhyKAoh80ZJQ',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1615920747,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 300000,
            unit_amount_decimal: '300000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 300000,
        unit_amount_decimal: '300000'
      },
      {
        id: 'price_1IUVxDB0P8XBvhyKsCV2I3a3',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1615636103,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 48000,
            unit_amount_decimal: '48000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 48000,
        unit_amount_decimal: '48000'
      },
      {
        id: 'price_1IUVuBB0P8XBvhyKRaKZLtA3',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1615635915,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 50000,
            unit_amount_decimal: '50000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 50000,
        unit_amount_decimal: '50000'
      },
      {
        id: 'price_1IUVf0B0P8XBvhyK5EVtwzNs',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1615634974,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 249000,
            unit_amount_decimal: '249000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: 'Gestion des cookies',
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 249000,
        unit_amount_decimal: '249000'
      },
      {
        id: 'price_1ISnqiB0P8XBvhyKcHkBX9KW',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1615228236,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 225000,
            unit_amount_decimal: '225000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: 'Gestion des cookies : 3 Millions de visites',
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 225000,
        unit_amount_decimal: '225000'
      },
      {
        id: 'price_1IQaG6B0P8XBvhyKqbq26RFu',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1614699338,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 112500,
            unit_amount_decimal: '112500'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          statsAllowedAggregateBy: 'hour,day,week,month,year',
          statsAreFiltersAllowed: 'true',
          statsWidgets: 'dailyOverview, dailyVisitsOverview, visitsByHosts, optInRateByConfig,optInRateByDevice,devicesDistribution,consentDistributionByDevice, consentDistribution, dailyRates',
          zd_priority: 'high'
        },
        nickname: 'PMU x Periode d\'essai - Tarif sur mesure',
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 3,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 112500,
        unit_amount_decimal: '112500'
      },
      {
        id: 'price_1IP9WnB0P8XBvhyKttFHv106',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1614358257,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 250000,
            unit_amount_decimal: '250000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: 'Gestion des cookies',
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 250000,
        unit_amount_decimal: '250000'
      },
      {
        id: 'price_1Hw3CuB0P8XBvhyK8nKLE6D8',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1607422448,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 150000,
            unit_amount_decimal: '150000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 150000,
        unit_amount_decimal: '150000'
      },
      {
        id: 'price_1Hw3CfB0P8XBvhyK1AZktEm1',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1607422433,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 15000,
            unit_amount_decimal: '15000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: null,
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 15000,
        unit_amount_decimal: '15000'
      },
      {
        id: 'price_1HcScxB0P8XBvhyK7cNulKaT',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1602753603,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 159900,
            unit_amount_decimal: '159900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: 'enterprise',
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 159900,
        unit_amount_decimal: '159900'
      },
      {
        id: 'price_1HcScDB0P8XBvhyKhX05hDBF',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1602753557,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 14900,
            unit_amount_decimal: '14900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: 'entreprise',
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 14900,
        unit_amount_decimal: '14900'
      },
      {
        id: 'price_1HcSRcB0P8XBvhyKRFC9e4pU',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1602752900,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 19900,
            unit_amount_decimal: '19900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: 'standard',
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 19900,
        unit_amount_decimal: '19900'
      },
      {
        id: 'price_1HcS4GB0P8XBvhyKdnwomNby',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1602751452,
        currency: 'usd',
        currency_options: {
          usd: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1900,
            unit_amount_decimal: '1900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal'
        },
        nickname: 'standard',
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1900,
        unit_amount_decimal: '1900'
      },
      {
        id: 'price_1HCPzhB0P8XBvhyKCxO6oKBJ',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1596546953,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 99000,
            unit_amount_decimal: '99000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          services: 'contracts',
          zd_priority: 'high'
        },
        nickname: 'enterprise',
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 99000,
        unit_amount_decimal: '99000'
      },
      {
        id: 'price_1HCPwfB0P8XBvhyK9ILinrjM',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1596546765,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9900,
            unit_amount_decimal: '9900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: 'enterprise',
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9900,
        unit_amount_decimal: '9900'
      },
      {
        id: 'plan_EK59JO3Qc05BEZ',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1547216846,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 15000,
            unit_amount_decimal: '15000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal'
        },
        nickname: 'standard',
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 15000,
        unit_amount_decimal: '15000'
      },
      {
        id: 'plan_EK56NVSaUTYeVX',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1547216717,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500,
            unit_amount_decimal: '1500'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal'
        },
        nickname: 'standard',
        product: 'prod_EK56LUOwFsZy8Y',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500,
        unit_amount_decimal: '1500'
      }
    ]
  },
  {
    id: 'prod_JltnPfDeZfgHNx',
    metadata: {
      backoffice: '3chocos',
      description_EN: '< 2M monthly visits • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • email support • Payment by bank transfer • Native application iOs and Andoid',
      description_FR: '< 2M visites par mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail • Paiement par virement • Application native iOs et Android',
      features: 'customization, consentWall',
      maximumMonthlyVisitors: '1000000',
      services: 'cookies,processings,dpo,contracts,portability',
      statsAllowedAggregateBy: 'hour,day,week,month,year',
      statsAreFiltersAllowed: 'true',
      statsPeriod: 'lastYear',
      statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, optInRateByDevice, devicesDistribution, visitsByHosts, consentDistributionByDevice, consentDistribution',
      zd_priority: 'high'
    },
    active: false,
    name: 'Triple chocos',
    type: 'service',
    prices: [
      {
        id: 'price_1J8LzhB0P8XBvhyKuZMTpJtO',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1625130937,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9900,
            unit_amount_decimal: '9900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          Code_comptable_product: '70610023'
        },
        nickname: 'Triple Chocos €99/mo',
        product: 'prod_JltnPfDeZfgHNx',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 9900,
        unit_amount_decimal: '9900'
      },
      {
        id: 'price_1J8LzhB0P8XBvhyK21xDZRJU',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1625130937,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 118800,
            unit_amount_decimal: '118800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Triple Chocos €1188/yr',
        product: 'prod_JltnPfDeZfgHNx',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 118800,
        unit_amount_decimal: '118800'
      }
    ]
  },
  {
    id: 'prod_Jltnh5CvEI2j9Q',
    metadata: {
      backoffice: '2choco',
      description_EN: '< 300K monthly visits • Unlimited cookies • Graphic customization • Multilingual • Storage of consents • Marketing consents • Contractual consents • email support',
      description_FR: '< 300K visites par mois • Cookies illimités • Personnalisation graphique • Multilingues • Stockage des consentements • Consentements marketing • Consentement contractuel • Support tel/mail',
      features: 'customization, consentWall',
      maximumMonthlyVisitors: '300000',
      services: 'cookies,processings,contracts,portability,dpo',
      statsAllowedAggregateBy: 'hour,day,week,month',
      statsAreFiltersAllowed: 'true',
      statsPeriod: 'lastMonth',
      statsWidgets: 'dailyOverview, optInRate, dailyVisitsOverview, visitsByHosts, consentDistribution',
      zd_priority: 'normal'
    },
    active: false,
    name: '2 chocos',
    type: 'service',
    prices: [
      {
        id: 'price_1J8LzZB0P8XBvhyK26fjYZkl',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1625130929,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4900,
            unit_amount_decimal: '4900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: '2 Chocos €49/mo',
        product: 'prod_Jltnh5CvEI2j9Q',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4900,
        unit_amount_decimal: '4900'
      },
      {
        id: 'price_1J8LzZB0P8XBvhyKRGmRcmd9',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1625130929,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 58800,
            unit_amount_decimal: '58800'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: '2 Chocos €588/yr',
        product: 'prod_Jltnh5CvEI2j9Q',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 58800,
        unit_amount_decimal: '58800'
      }
    ]
  },
  {
    id: 'prod_JfWlUYixcSn3WI',
    metadata: {},
    active: false,
    name: 'Offre Deux Chocos',
    type: 'service',
    prices: [
      {
        id: 'price_1J2BiMB0P8XBvhyKL8qexkmc',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623661454,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 4900,
            unit_amount_decimal: '4900'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Offre Deux Chocos',
        product: 'prod_JfWlUYixcSn3WI',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 4900,
        unit_amount_decimal: '4900'
      }
    ]
  },
  {
    id: 'prod_EK54hfQTQQBJDx',
    metadata: {
      services: 'processings'
    },
    active: false,
    name: 'Processings',
    type: 'service',
    prices: [
      {
        id: 'price_1J1rMNB0P8XBvhyKl9VDpCxE',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623583211,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 39600,
            unit_amount_decimal: '39600'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: 'Offre Triple Chocos Annuelle',
        product: 'prod_EK54hfQTQQBJDx',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 39600,
        unit_amount_decimal: '39600'
      },
      {
        id: 'price_1J1quBB0P8XBvhyKnwoqyxD1',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623581463,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1633,
            unit_amount_decimal: '1633'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal'
        },
        nickname: 'Offre deux chocos Mensuelle',
        product: 'prod_EK54hfQTQQBJDx',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1633,
        unit_amount_decimal: '1633'
      },
      {
        id: 'price_1IxqPCB0P8XBvhyKGQ5qVKAI',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1622626230,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 19600,
            unit_amount_decimal: '19600'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal'
        },
        nickname: 'Offre 2 Chocos',
        product: 'prod_EK54hfQTQQBJDx',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 19600,
        unit_amount_decimal: '19600'
      },
      {
        id: 'price_1IlGmFB0P8XBvhyKwvx6jWO9',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1619629339,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_EK54hfQTQQBJDx',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      },
      {
        id: 'price_1IlGkqB0P8XBvhyKKkMkHmOX',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1619629252,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_EK54hfQTQQBJDx',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      },
      {
        id: 'plan_EK555jEoVfSLQc',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1547216651,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 10000,
            unit_amount_decimal: '10000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'low'
        },
        nickname: 'Processings Yearly',
        product: 'prod_EK54hfQTQQBJDx',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 10000,
        unit_amount_decimal: '10000'
      },
      {
        id: 'plan_EK55PXn95UPRqQ',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1547216607,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000,
            unit_amount_decimal: '1000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'low'
        },
        nickname: 'Processings Monthly',
        product: 'prod_EK54hfQTQQBJDx',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1000,
        unit_amount_decimal: '1000'
      }
    ]
  },
  {
    id: 'prod_EK53bRVeY4B53x',
    metadata: {
      services: 'contracts'
    },
    active: false,
    name: 'Contracts',
    type: 'service',
    prices: [
      {
        id: 'price_1J1rM5B0P8XBvhyKl7DuqZeC',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623583193,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 39600,
            unit_amount_decimal: '39600'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'high'
        },
        nickname: 'Offre Triple Chocos Annuelle',
        product: 'prod_EK53bRVeY4B53x',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 39600,
        unit_amount_decimal: '39600'
      },
      {
        id: 'price_1J1qugB0P8XBvhyKZPwgGIxP',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1623581494,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1633,
            unit_amount_decimal: '1633'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal'
        },
        nickname: 'Offre deux chocos Mensuelle',
        product: 'prod_EK53bRVeY4B53x',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1633,
        unit_amount_decimal: '1633'
      },
      {
        id: 'price_1IxqPjB0P8XBvhyKnMnPQege',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1622626263,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 19600,
            unit_amount_decimal: '19600'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal'
        },
        nickname: 'Offre 2 Chocos',
        product: 'prod_EK53bRVeY4B53x',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 19600,
        unit_amount_decimal: '19600'
      },
      {
        id: 'price_1IlGl7B0P8XBvhyKwhwMPgas',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1619629269,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_EK53bRVeY4B53x',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      },
      {
        id: 'plan_EK54w5PASnn7pD',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1547216566,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 15000,
            unit_amount_decimal: '15000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal'
        },
        nickname: 'Contracts Yearly',
        product: 'prod_EK53bRVeY4B53x',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 15000,
        unit_amount_decimal: '15000'
      },
      {
        id: 'plan_EK54BWp89WXHz3',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1547216546,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500,
            unit_amount_decimal: '1500'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {
          zd_priority: 'normal'
        },
        nickname: 'Contracts Monthly',
        product: 'prod_EK53bRVeY4B53x',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1500,
        unit_amount_decimal: '1500'
      }
    ]
  },
  {
    id: 'prod_JbTZOKF7Ef3oVt',
    metadata: {
      Support_level: 'Premium'
    },
    active: false,
    name: 'Produit de tests',
    type: 'service',
    prices: [
      {
        id: 'price_1IyGcGB0P8XBvhyKGffKP3VF',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1622726984,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 49000,
            unit_amount_decimal: '49000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JbTZOKF7Ef3oVt',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 49000,
        unit_amount_decimal: '49000'
      }
    ]
  },
  {
    id: 'prod_JIfUv9EgYWJU4N',
    metadata: {},
    active: false,
    name: 'SDK mobile iOS et Android',
    type: 'service',
    prices: [
      {
        id: 'price_1Ig49jB0P8XBvhyKnBveJECH',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1618389183,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 70560,
            unit_amount_decimal: '70560'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_JIfUv9EgYWJU4N',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 70560,
        unit_amount_decimal: '70560'
      }
    ]
  },
  {
    id: 'prod_J7WnIZwQwTMcSB',
    metadata: {},
    active: true,
    name: 'GDPR cards (paper)',
    type: 'service',
    prices: [
      {
        id: 'price_1IVHjwB0P8XBvhyK4zjEPJZQ',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1615819792,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 9999,
            unit_amount_decimal: '9999'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_J7WnIZwQwTMcSB',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 9999,
        unit_amount_decimal: '9999'
      }
    ]
  },
  {
    id: 'prod_J7Wn3lQu6MiRHj',
    metadata: {},
    active: true,
    name: 'GDPR cards (online)',
    type: 'service',
    prices: [
      {
        id: 'price_1IVHjcB0P8XBvhyKD4ltcEgW',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1615819772,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 2999,
            unit_amount_decimal: '2999'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_J7Wn3lQu6MiRHj',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 2999,
        unit_amount_decimal: '2999'
      }
    ]
  },
  {
    id: 'prod_J34UJXVj2ovXCQ',
    metadata: {},
    active: false,
    name: 'Intégration',
    type: 'service',
    prices: [
      {
        id: 'price_1IQyL9B0P8XBvhyKYL2ZYBar',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1614791907,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 80000,
            unit_amount_decimal: '80000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_J34UJXVj2ovXCQ',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 80000,
        unit_amount_decimal: '80000'
      }
    ]
  },
  {
    id: 'prod_IhDjORkNxguKrY',
    metadata: {},
    active: false,
    name: 'Cookies Standard Yearly',
    type: 'service',
    prices: [
      {
        id: 'cookiesyearly',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1609752206,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 15000,
            unit_amount_decimal: '15000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_IhDjORkNxguKrY',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 15000,
        unit_amount_decimal: '15000'
      }
    ]
  },
  {
    id: 'prod_I3wnyujTMO5aCy',
    metadata: {},
    active: false,
    name: 'Axeptio Gestion des cookies',
    type: 'service',
    prices: [
      {
        id: 'price_1HToteB0P8XBvhyK1f1ZcsuF',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1600694254,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1500,
            unit_amount_decimal: '1500'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_I3wnyujTMO5aCy',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'one_time',
        unit_amount: 1500,
        unit_amount_decimal: '1500'
      }
    ]
  },
  {
    id: 'prod_HjcrhXzy3bd053',
    metadata: {},
    active: true,
    name: 'Le CacheCam® d\'Axeptio',
    type: 'service',
    prices: [
      {
        id: 'price_1HA9bYB0P8XBvhyKEioj1s9R',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1596007296,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000,
            unit_amount_decimal: '1000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_HjcrhXzy3bd053',
        recurring: null,
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: {
          divide_by: 5,
          round: 'up'
        },
        type: 'one_time',
        unit_amount: 1000,
        unit_amount_decimal: '1000'
      }
    ]
  },
  {
    id: 'prod_HhmtkSrzbrHoWW',
    metadata: {},
    active: false,
    name: 'Offre Agence Personnalisée',
    type: 'service',
    prices: [
      {
        id: 'price_1H8NJOB0P8XBvhyKmydpIq60',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1595583330,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 30000,
            unit_amount_decimal: '30000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_HhmtkSrzbrHoWW',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: 15,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 30000,
        unit_amount_decimal: '30000'
      }
    ]
  },
  {
    id: 'prod_HZWbGMYVHyQbz1',
    metadata: {
      services: 'cookies,processings'
    },
    active: false,
    name: 'Axeptio',
    type: 'service',
    prices: [
      {
        id: 'price_1H0NXqB0P8XBvhyKKiZ5LJNf',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1593677602,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 25000,
            unit_amount_decimal: '25000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: null,
        product: 'prod_HZWbGMYVHyQbz1',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 25000,
        unit_amount_decimal: '25000'
      }
    ]
  },
  {
    id: 'prod_GOFRmrA7YtrrRl',
    metadata: {},
    active: false,
    name: 'Axeptio Formule Cookie Pro',
    type: 'service',
    prices: [
      {
        id: 'plan_GOFUTTvOPGSDiW',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1576777275,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 50000,
            unit_amount_decimal: '50000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Formule Pro',
        product: 'prod_GOFRmrA7YtrrRl',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 50000,
        unit_amount_decimal: '50000'
      }
    ]
  },
  {
    id: 'prod_EK51dkcDkiUYeR',
    metadata: {
      services: 'portability'
    },
    active: false,
    name: 'Portability',
    type: 'service',
    prices: [
      {
        id: 'plan_F1FAPxyErAsoAV',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1557171648,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000,
            unit_amount_decimal: '1000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Plan de test 15 jours free trial',
        product: 'prod_EK51dkcDkiUYeR',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: 15,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1000,
        unit_amount_decimal: '1000'
      },
      {
        id: 'plan_EK52rY1ao30RDA',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1547216470,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 1000,
            unit_amount_decimal: '1000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Portability Monthly',
        product: 'prod_EK51dkcDkiUYeR',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 1000,
        unit_amount_decimal: '1000'
      },
      {
        id: 'plan_EK52mn0VmTfRfM',
        object: 'price',
        active: false,
        billing_scheme: 'per_unit',
        created: 1547216430,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 10000,
            unit_amount_decimal: '10000'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Portability Yearly',
        product: 'prod_EK51dkcDkiUYeR',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 10000,
        unit_amount_decimal: '10000'
      }
    ]
  },
  {
    id: 'prod_EK4y2UJsOOkhN3',
    metadata: {
      services: 'privacypolicy'
    },
    active: false,
    name: 'Privacy Policy',
    type: 'service',
    prices: [
      {
        id: 'plan_EK50il5T7mxm9G',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1549356301,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Privacy Policy Monthly',
        product: 'prod_EK4y2UJsOOkhN3',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      },
      {
        id: 'plan_ELW93Mgba1zjLr',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1547547935,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'Privacy Policy Yearly',
        product: 'prod_EK4y2UJsOOkhN3',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      }
    ]
  },
  {
    id: 'prod_EK4zTxFSUyi81Q',
    metadata: {
      services: 'dpo'
    },
    active: false,
    name: 'DPO',
    type: 'service',
    prices: [
      {
        id: 'plan_EK50V8LPUD3TDo',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1547216314,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'DPO Yearly',
        product: 'prod_EK4zTxFSUyi81Q',
        recurring: {
          aggregate_usage: null,
          interval: 'year',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      },
      {
        id: 'plan_EK50B7g5jAMCGd',
        object: 'price',
        active: true,
        billing_scheme: 'per_unit',
        created: 1547216304,
        currency: 'eur',
        currency_options: {
          eur: {
            custom_unit_amount: null,
            tax_behavior: 'unspecified',
            unit_amount: 0,
            unit_amount_decimal: '0'
          }
        },
        custom_unit_amount: null,
        livemode: true,
        lookup_key: null,
        metadata: {},
        nickname: 'DPO Monthly',
        product: 'prod_EK4zTxFSUyi81Q',
        recurring: {
          aggregate_usage: null,
          interval: 'month',
          interval_count: 1,
          meter: null,
          trial_period_days: null,
          usage_type: 'licensed'
        },
        tax_behavior: 'unspecified',
        tiers_mode: null,
        transform_quantity: null,
        type: 'recurring',
        unit_amount: 0,
        unit_amount_decimal: '0'
      }
    ]
  }
];

export default products;